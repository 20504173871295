import {
    Datagrid,
    List,
    TextField,
    BooleanField
} from 'react-admin';
import React from 'react';
import { AdminUserFilter } from './admin-user-filter';
import {CustomListActions} from "../../layout/custom-list-actions";
import {Role} from "../../utils/role";
import {rolesTree} from "../../utils/admin-roles";

export const AdminUserList = ({ ...props }: any) => {

    return <List {...props} title="Admin felhasználók" filters={<AdminUserFilter rolesTree={rolesTree}/>} exporter={false}
                 bulkActionButtons={false}
                 actions={<CustomListActions role={Role.AdminUserEdit}/>}
    >
        <Datagrid rowClick="show">
            <TextField source="id" label="ID"/>
            <TextField source="name" label="Név"/>
            <TextField source="email" label="Email"/>
            {/*<EnumField source="role" label="Szint" translatorFunction={getRoleName} addLabel/>*/}
            <BooleanField source="isActive" label="Aktív"/>
        </Datagrid>
    </List>;
};
