import {ArrayInput, BooleanInput, Edit, PasswordInput, ReferenceInput, required, TextInput} from 'react-admin';
import React from 'react';
import {RolesInput} from './roles-input';
import {EditWithoutDelete} from "../../layout/edit-without-delete";
import {rolesTree} from "../../utils/admin-roles";

export const AdminUserEdit = ({ ...props }: any) => {
    return <Edit {...props} title="Admin felhsználó szerkesztése">
        <EditWithoutDelete>
            <BaseAdminUserForm path="" tabLabel="adatok"/>
            <AdminUserRolesForm path="roles" tabLabel="Jogosultságok" rolesTree={rolesTree}/>
        </EditWithoutDelete>
    </Edit>;
};

export const BaseAdminUserForm = ({ fromCreate = false, ...props }: any) => {
    return <div>
        <TextInput label="Név" source="name" validate={[required()]} fullWidth/>
        <TextInput label="Email" source="email" validate={[required()]} fullWidth/>
        <PasswordInput label="Jelszó" source="first_password" validate={fromCreate ? [required()] : []} fullWidth
                       initialValue=''/>
        <PasswordInput label="Jelszó újra" source="second_password" validate={fromCreate ? [required()] : []} fullWidth
                       initialValue=''/>
        <br/>
        <BooleanInput label="Aktív" source="isActive"/>
        {/*<EnumArrayInput source='selectedRoles' originalSource='roles' choices={getRoles()} record={props?.record}*/}
        {/*                label="Szintek"/>*/}
    </div>;
};

export const AdminUserRolesForm = ({ rolesTree, ...props }: any) => {
    return <>
        <ArrayInput source="roles" label="">
            <ReferenceInput resource="admin" reference="admin-user-role" source="name" perPage={1000000}>
                <RolesInput rolesTree={rolesTree}/>
            </ReferenceInput>
        </ArrayInput>
    </>;
};
