import React, { useState } from 'react';
import { AutocompleteInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { 
    useRefresh,
    Error,
    useNotify,
    useQuery, 
    SimpleForm,
    FormDataConsumer,
    BooleanInput
} from 'react-admin';
import { useSelector } from 'react-redux';
import { getJwtHeader } from '../../connection/auth-provider';
import { postWithAuth } from '../../connection/fetch-with-auth';
import { useDialogStyles } from '../../layout/common-styles';
import { DefaultToolbar } from '../../layout/default-toolbar';

interface BulkEditProps {
    onCancel: () => void;
    onSave: (post:any) => void;
    selectedIds: number[];
    resource: string;
    source: string;
    bulkEndpoint: string;
    needConfirm: boolean;
    editLabel: string;
    reference: string;
    type: BulkEditType;
}

export enum BulkEditType {
    boolean, autocomplete
}


const renderMe = (data: any) => {
    if (data) {
        return data.name;
    }
    return '';
};

export const BulkEdit = (props: BulkEditProps) => {
    const [val, setVal] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    const { data, loading, error }: any = useQuery({
        type: 'getMany',
        resource: props.resource,
        payload: { ids: props.selectedIds },
        headers: { 'Authorization': getJwtHeader() }
    });
    const classes: any = useDialogStyles();
    const submitting = useSelector((state: any) => state.admin.loading > 0);

    if (loading) return null;
    if (error) return <Error error={error} errorInfo={'error'}/>;
    if (!data) return null;

    return (
        <SimpleForm
            save={async (event: any) => {
                data.forEach((item:any) => {
                    item[props.source] = val;
                });

                const result = await postWithAuth(`${props.resource}/${props.bulkEndpoint}`, {
                    data: data
                });

                if (result.status === 201 || result.status === 200) {
                    props.onSave(null);
                    refresh();
                } else {
                    notify('Valami hiba történt');
                }
            }}
            saving={submitting}
            redirect={false}
            toolbar={<DefaultToolbar onCancel={props.onCancel} submitting={submitting}/>}
            classes={{ form: classes.form }}
        >

            <FormDataConsumer>
                {({ formData, ...rest }:any) => {
                    if(formData[props.source]) {
                        setVal(formData[props.source]);
                    }
                    switch(props.type){
                        case BulkEditType.boolean:
                            return <BooleanInput source={props.source} label={props.editLabel} addLabel={props.editLabel!!}/>;
                        case BulkEditType.autocomplete:
                            return <></>;
                    }
                }}
            </FormDataConsumer>
            {props.type === BulkEditType.autocomplete && <ReferenceInput 
                            resource={props.resource} 
                            source={props.source} 
                            reference={props.reference}
                            label={props.editLabel}
                            filterToQuery={(searchText: any) => ({ 'name': searchText || '%' })}
                            addLabel={props.editLabel!!}
                            fullWidth>
                                <AutocompleteInput
                                optionValue="id" 
                                optionText={renderMe}
                                label=""
                                shouldRenderSuggestions={(val: any) => {
                                    return val.trim().length > 2;
                                }}
                                allowEmpty={true}
                                emptyValue={null}
                                emptyText={''}/>
                        </ReferenceInput>}
        </SimpleForm>
    );
};