import React, {useEffect, useState} from 'react';
import {Button, makeStyles, TextField} from "@material-ui/core";
import {getWithAuth, putWithAuth} from "../../connection/fetch-with-auth";
import {useNotify} from "ra-core";

const FacebookSettings = (props: any) => {
    const classes = useStyles();
    const validUntil = useAccessTokenValidUntil();
    const [newToken, setNewToken] = useState('');
    const notify = useNotify();

    const handleSubmitNewToken = () => {
        putWithAuth('facebook/access_token', {
            token: newToken
        })
            .then(res => {
                notify("Sikeres módosítás", "success");
            })
            .catch(e => {
                console.error(e);
                notify(e?.response?.data?.message, 'error');
            })
    }

    const expireDate = validUntil ? (new Date(validUntil)).toLocaleDateString() : '';

    return <div>
        <div className={classes.validUntilRow}>Jelenlegi kulcs eddig érvényes: {expireDate ?? ''}</div>
        <div className={classes.newTokenRow}>
            <TextField
                className={classes.newTokenRowItem}
                label="Új kulcs"
                fullWidth
                value={newToken}
                onChange={(e) => setNewToken(e.target.value)}
            />
            <Button
                variant="contained"
                color="primary"
                className={classes.newTokenRowItem}
                onClick={handleSubmitNewToken}
            >
                Frissítés
            </Button>
        </div>
    </div>
};

const useStyles = makeStyles(() => ({
    newTokenRow: {
        display: 'flex',
        marginTop: 10,
    },
    newTokenRowItem: {
        margin: 8,
    },
    validUntilRow: {
        margin: 8,
    },
}));

const useAccessTokenValidUntil = () => {
    const [validUntil, setValidUntil] = useState(null);

    useEffect(() => {
        getWithAuth(`facebook/access_token_expiry_date`)
            .then(res => setValidUntil(res?.data))
            .catch(err => console.log(err));
    }, []);

    return validUntil;
}

export default FacebookSettings;
