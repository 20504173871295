// in src/comments.js
import * as React from 'react';
import {useState} from 'react';
import {FunctionField, ReferenceField, ShowButton, SingleFieldList, TextField, useListContext} from 'react-admin';
import {Card, CardActions, CardContent, CardHeader, Checkbox, makeStyles} from '@material-ui/core';
import {ReferenceObjectArrayField} from '../fields/reference-object-array-field';
import {OwnChipField} from '../fields/ownchipfield';
import {AddNewTagField} from '../fields/add-tag-field';
import {HungarianDateTimeField} from '../fields/hungarial-date-field';
import {BooleanImediateSaver} from '../fields/boolean-saver-input';

const cardStyle = makeStyles(theme => ({
    card: {
        width: 'calc(33.33% - 1em)',
        minHeight: 300,
        margin: '0.5em',
        display: 'inline-block',
        verticalAlign: 'top',
        [theme.breakpoints.down('md')]: {  
            width: 'calc(50% - 1em)',
        }
    },
    image:{
        width: 150,
    },
    noPadding: {
        paddingTop: 0,
        paddingBottom: '7px',
    },
    flexbox:{
        display: 'flex'
    },
    checkBox: {
        alignSelf: 'start',
    },
    allSelect:{
        width: '100%',
        marginBottom: '10px',
    },
    addTag: {
       '& #newTag-helper-text':{
           display: 'none',
       },
       '& #MuiFormControl-marginDense': {
           marginTop:0,
           marginBottom:0
       }
    }
}));
export const NewsDataGrid = () => {
    const { ids, data, basePath, selectedIds, onToggleItem, onSelect, onUnselectItems } = useListContext();
    const [selectAll, setSelectAll] = useState(false);
    
    const styles = cardStyle();
    return (
        <div style={{ margin: '1em' }}>
            <div className={styles.allSelect}>
                <p>
                    Összes kijelölése
                <Checkbox
                    checked={selectAll}
                    onClick={() => {
                        if(selectAll){
                            onUnselectItems();
                            setSelectAll(false);
                        }else {
                            onSelect(ids);
                            setSelectAll(true);
                        }
                    }}
                />
                </p>
            </div>
        {ids.map((id:any) =>
            <Card key={id} className={styles.card}>
                <div className={styles.flexbox}>
                <CardContent>
                    <FunctionField record={data[id]} source="lead" render={(data:any)=>
                        <picture>
                            <img 
                                className={styles.image}
                                src={data.picture}
                                alt="Nincs kép"/>
                        </picture>
                    }/>
                </CardContent>
                <CardHeader
                    title={<TextField record={data[id]} source="title" />}
                    subheader={<HungarianDateTimeField record={data[id]} source="createdAt" />}
                />
                <div style={{flex: 1}}>
                </div>
                <Checkbox
                    className = {styles.checkBox}
                    disabled={data[id].selectable}
                    checked={selectedIds.includes(id)}
                    onClick={() => onToggleItem(id)}
                />
                </div>
                <CardContent className={styles.noPadding}>
                    <ReferenceField basePath={basePath} record={data[id]} source="source.id" reference="source-admin">
                        <TextField record={data[id]} source="name" />
                    </ReferenceField>
                </CardContent>
                <CardContent className={styles.noPadding}>
                    <div className={styles.flexbox}>
                        <FunctionField record={data[id]}  source='isActive' addLabel={false} render={(data:any)=>{
                            return <BooleanImediateSaver value={data.isActive} endpointToCall={'news-admin/updateBoolean'} recordId={id} source={'isActive'} label={'Hír engedélyezve'}/>
                        }}/>
                        <FunctionField record={data[id]}  source='shouldDisplayPicture' addLabel={false} render={(data:any)=>{
                            return <BooleanImediateSaver value={data.shouldDisplayPicture} endpointToCall={'news-admin/updateBoolean'} recordId={id} source={'shouldDisplayPicture'} label={'Kép megjelenik'}/>
                        }}/>
                    </div>
                </CardContent>
                <CardContent className={styles.noPadding}>
                    <div className={styles.flexbox}>
                    <ReferenceObjectArrayField basePath={basePath} record={data[id]} arraySource="id" resource="news-admin" source="tags" reference="tag-admin"
                                                label="tag-ek" link={false} fullWidth>
                        <SingleFieldList linkType={false}>
                            <OwnChipField source="name" newsId={id}/>
                        </SingleFieldList>
                    </ReferenceObjectArrayField>
                    <AddNewTagField newsId={id} className={styles.addTag}/>
                    </div>
                </CardContent>
                <CardContent>
                    <TextField record={data[id]} source="lead" />
                </CardContent>
                <CardActions style={{ textAlign: 'right' }}>
                    <ShowButton resource="news-admin" basePath={basePath} record={data[id]} />
                </CardActions>
            </Card>
        )}
        </div>
    );
};
