import get from 'lodash/get';
import set from 'lodash/set';
import { ReferenceArrayField } from 'react-admin';
import React from 'react';

export const ReferenceObjectArrayField = ({ record, children, source, arraySource = 'id', ...props }: any) => {
    const clonedRecord = Object.assign({}, record);

    const array = get(clonedRecord, source) || [];

    set(clonedRecord, source, array.map((element: any) => get(element, arraySource)));

    return <ReferenceArrayField {...props} record={clonedRecord} source={source}>
        {children}
    </ReferenceArrayField>;
};
