import * as React from 'react';
import {cloneElement} from 'react';
import {CreateButton, ExportButton, sanitizeListRestProps, TopToolbar, useListContext} from 'react-admin';
import {getJwtData} from "../connection/auth-provider";

export const CustomListActions = (props: any) => {
    const jwtData = getJwtData();

    const {
        className,
        exporter,
        filters,
        maxResults,
        role,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        showFilter,
        total
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button'
            })}
            {(!role || jwtData?.roles.includes(role)) && !(props?.noCreate) && hasCreate &&
            <CreateButton basePath={basePath}/>
            }
            {exporter &&
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            }
        </TopToolbar>
    );
};
