import {EditButton, FunctionField, List, ReferenceField, TextField, useListContext} from 'react-admin';
import React, {useEffect, useState} from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Checkbox, makeStyles} from '@material-ui/core';
import {HungarianDateTimeField} from '../../fields/hungarial-date-field';
import {BooleanImediateSaver} from '../../fields/boolean-saver-input';
import {ListActionsWithSortButton} from '../../fields/actions-sort-button';
import {BulkActions} from './source-bulk-actions';
import {SourceFilter} from './source-filters';
import {getWithAuth} from "../../connection/fetch-with-auth";
import {Skeleton} from "@material-ui/lab";

export const SourceList = ({...props}: any) => {

    return <List {...props} title="Források" exporter={false}
                actions={<ListActionsWithSortButton sortFields={['name', 'id']} addCreateButton />}
                bulkActionButtons={
                    <BulkActions/>}
                filters={<SourceFilter/>}
    >
        <CardGrid/>
    </List>;
};

const cardStyle = makeStyles(theme => ({
    card: {
        width: 'calc(33.33% - 1em)',
        margin: '0.5em',
        display: 'inline-block',
        verticalAlign: 'top',
        [theme.breakpoints.down('md')]: {  
            width: 'calc(50% - 1em)',
        }
    },
    image:{
        width: 150,
    },
    noPadding: {
        paddingTop: 0,
        paddingBottom: '7px',
    },
    flexbox:{
        display: 'flex',
        flexWrap: 'wrap',
    },
    checkBox: {
        alignSelf: 'start',
    },
    allSelect:{
        width: '100%',
        marginBottom: '10px',
    },
    addTag: {
       '& #newTag-helper-text':{
           display: 'none',
       },
       '& #MuiFormControl-marginDense': {
           marginTop:0,
           marginBottom:0
       }
    }
}));

const CardGrid = () => {
    const { ids, data, basePath, selectedIds, onToggleItem, onSelect, onUnselectItems } = useListContext();
    const [selectAll, setSelectAll] = useState(false);
    const [lastNewsCreatedAt, setLastNewsCreatedAt] = useState([]);
    const [fetchingIds, setFetchingIds] = useState([]);

    useEffect(() => {
        const existingIds = lastNewsCreatedAt.map(it => (it as any)?.id);
        const missingIds = ids.filter((id: number) => !existingIds.includes(id));

        if (!missingIds || missingIds.filter((id: any) => !(fetchingIds as any).includes(id)).length === 0)
            return;

        setFetchingIds(missingIds);
        getWithAuth(`source-admin/lastNewsDates?ids=[${missingIds.join(',')}]`)
            .then((res: any) => {
                if (res.data?.length > 0) {
                    res.data.forEach((data: any) => {
                        (lastNewsCreatedAt[data.id] as any) = data;
                    })
                    setLastNewsCreatedAt([...lastNewsCreatedAt]);
                    setFetchingIds([]);
                }
            });
    }, [ids, fetchingIds, lastNewsCreatedAt]);

    const styles = cardStyle();
    return (
        <div style={{ margin: '1em' }}>
            <div className={styles.allSelect}>
                <p>
                    Összes kijelölése
                <Checkbox
                    checked={selectAll}
                    onClick={() => {
                        if(selectAll){
                            onUnselectItems();
                            setSelectAll(false);
                        }else {
                            onSelect(ids);
                            setSelectAll(true);
                        }
                    }}
                />
                </p>
            </div>
        {ids.map((id:any) =>
            <Card key={id} className={styles.card}>
                <div className={styles.flexbox}>
                <CardContent>
                    <FunctionField record={data[id]} source="picture" render={(data:any)=>
                        <picture>
                            <img 
                                className={styles.image}
                                src={data.picture}
                                alt="Nincs logó"/>
                        </picture>
                    }/>
                </CardContent>
                <CardHeader style={{alignSelf: 'start'}}
                    title={<FunctionField record={data[id]} source="id" render={(data:any)=>{
                        return <p>{data.id}: {data.name}</p>
                    }
                    }/>}
                    subheader={<HungarianDateTimeField record={data[id]} source="createdAt" />}
                />
                <div style={{alignSelf: 'center'}}>
                    <FunctionField  record={data[id]}  source='isActive' addLabel={false} render={(data:any)=>{
                        return <BooleanImediateSaver value={data.isActive} endpointToCall={'source-admin/updateBoolean'} recordId={id} source={'isActive'} label={'Forrás engedélyezve'}/>
                    }}/>
                </div>
                <div style={{flex: 1}}></div>
                <Checkbox
                    className = {styles.checkBox}
                    disabled={data[id].selectable}
                    checked={selectedIds.includes(id)}
                    onClick={() => onToggleItem(id)}
                />
                </div>
                <CardContent className={styles.noPadding}>
                    <ReferenceField basePath={basePath} record={data[id]} source="createdBy.id" reference="admin">
                        <TextField record={data[id]} source="name" />
                    </ReferenceField>
                </CardContent>
                <CardContent className={styles.noPadding}>
                    <TextField record={data[id]} source="note" />
                </CardContent>
                <CardActions style={{ textAlign: 'right' }}>
                    <div className={styles.flexbox}>
                        <Button  href={`#/news-admin/?displayedFilters={"source.id"%3Atrue}&filter={"source"%3A{"id"%3A${id}}}&sort=id&order=DESC`}>
                            Hír lista
                        </Button>
                        <div style={{flex: 1}}></div>
                        <EditButton record={data[id]} basePath={basePath}/>
                    </div>
                </CardActions>
                <CardContent className={styles.noPadding}>
                    <FunctionField  record={data[id]}  addLabel={false} render={(data:any)=>{
                        return <div style={{display: 'flex'}}>
                            <div style={{ whiteSpace: 'pre' }}>Utolsó hír: </div>
                            {lastNewsCreatedAt[id] ?
                                <HungarianDateTimeField record={lastNewsCreatedAt[id]} source="createdAt" label="Utolsó hír dátum" addLabel/>
                                :
                                <Skeleton variant="rect" width={135} height={20}/>
                            }
                        </div>

                    }}/>
                </CardContent>
            </Card>
        )}
        </div>
    );
};
