import React from 'react';
import {useSelector} from 'react-redux';
import {MenuItemLink} from 'react-admin';
import {withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {List} from '@material-ui/core';
import {Category as CategoryIcon, Image, Person as PersonIcon} from '@material-ui/icons';
import {Role} from "../utils/role";
import {getJwtData} from "../connection/auth-provider";
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import LinkIcon from '@material-ui/icons/Link';
import BlockIcon from '@material-ui/icons/Block';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles(theme => ({
    menuItem: {
        marginLeft: '16px',
        marginTop: '8px',
        marginBottom: '8px'
    },
    mainMenuItem: {
        height: '48px'
    },
    mainMenuItemIcon: {
        paddingRight: '16px'
    },
    icon: {minWidth: theme.spacing(5), marginRight: '16px'}
}));

const MenuItem = ({title, image, to, onClick, role = null, currentPath}: any) => {
    // @ts-ignore
    const isSideBarOpen = useSelector(state => state.admin.ui.sidebarOpen);

    const classes = useStyles();
    const jwtData = getJwtData();
    if (role && (jwtData && !jwtData.roles.includes(role))) return null;

    return <MenuItemLink
        className={classes.menuItem}
        selected={currentPath.includes(to) && to === currentPath.match('/[^/]*')[0]}
        key={to}
        leftIcon={image ? image : null}
        sidebarIsOpen={isSideBarOpen}
        to={to}
        primaryText={title}
        onClick={onClick}
    />;
};

const Menu = ({onMenuClick, logout, ...props}: any) => {
    const pathName = props.location.pathname;
    return (
        <div>
            <List component="nav">
                <MenuItem title="Témakörök" image={<CategoryIcon/>} to="/tag-admin" onClick={onMenuClick}
                          role={Role.TopicView} currentPath={pathName}/>
                <MenuItem title="Tag összeolvadások" image={<LinkIcon/>} to="/tag-merge-admin" onClick={onMenuClick}
                          role={Role.TopicView} currentPath={pathName}/>
                <MenuItem title="Tag összeolvadás ajánlások" image={<LinkIcon/>} to="/tag-suggested-merge-admin"
                          onClick={onMenuClick}
                          role={Role.TopicEdit} currentPath={pathName}/>
                <MenuItem title="Tag képek" image={<Image/>} to="/tag-pictures"
                          onClick={onMenuClick}
                          role={Role.TopicEdit} currentPath={pathName}/>
                <MenuItem title="Hírek" image={<LibraryBooksOutlinedIcon/>} to="/news-admin" onClick={onMenuClick}
                          role={Role.NewsView} currentPath={pathName}/>
                <MenuItem title="Admin Felhasználók" image={<PersonIcon/>} to="/admin" onClick={onMenuClick}
                          role={Role.AdminUserView} currentPath={pathName}/>
                <MenuItem title="Forrás menedzsment" image={<CloudDownloadOutlinedIcon/>} to="/source-admin"
                          onClick={onMenuClick}
                          role={Role.SourceView} currentPath={pathName}/>
                <MenuItem title="Blokkolások" image={<BlockIcon/>} to="/top-news-blocklist" onClick={onMenuClick}
                          role={Role.TopNewsBlocklistView} currentPath={pathName}/>
                <MenuItem title="Blokkolt kulcsszavak" image={<BlockIcon/>} to="/blocked-keywords-admin" onClick={onMenuClick}
                          role={Role.TopNewsBlocklistView} currentPath={pathName}/>
                <MenuItem title="Facebook" image={<FacebookIcon/>} to="/facebook" onClick={onMenuClick}
                          role={Role.FacebookSetting} currentPath={pathName}/>
            </List>
        </div>
    );
};

export default withRouter(Menu);
