export enum BlocklistTargetTypeEnum {
    News = 'news',
    Tag = 'tag',
    Source = 'source',
}

export const getBlocklistTargetTypeName = (targetType: BlocklistTargetTypeEnum) => {
    switch (targetType) {
        case BlocklistTargetTypeEnum.News:
            return 'Hír';
        case BlocklistTargetTypeEnum.Tag:
            return 'Tag';
        case BlocklistTargetTypeEnum.Source:
            return 'Forrás';
        default:
            return '';
    }
}

export const getBlockListTargetTypeChoices = () => {
    return [
        BlocklistTargetTypeEnum.Source,
        BlocklistTargetTypeEnum.Tag,
        BlocklistTargetTypeEnum.News,
    ].map(targetType => ({
        id: targetType,
        name: getBlocklistTargetTypeName(targetType),
    }));
}
