import {CondOperator, QueryFilter, RequestQueryBuilder} from '@nestjsx/crud-request';
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY
} from 'react-admin';


const convertFileToBase64 = (file: Blob) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
});

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
export const addUploadFeature = (requestHandler: any) => async (type: string, resource: string, params: any) => {

    /*params.data._references = [
      {
        resource: "product-data",
        path: "productData",
        isArray: true
      }
    ];*/


    if (!(type === 'UPDATE' || type === 'CREATE')) {
        return requestHandler(type, resource, params);
    }

    for (const property in params.data) {
        if (!params.data.hasOwnProperty(property)) {
            continue;
        }

        console.log(`${property}: ${params.data[property]}`);
        if (params.data.hasOwnProperty(property) && params.data[property]) {
            if (params.data[property].hasOwnProperty('undefined')) {
                params.data[property].base64content = await convertFileToBase64(params.data[property].rawFile);
            } else if (Array.isArray(params.data[property]) && params.data[property].length > 0) {
                for (const element of params.data[property]) {
                    if (element?.hasOwnProperty('undefined')) {
                        element.base64content = await convertFileToBase64(element.rawFile);
                    } else {
                        for (const key in element) {
                            if (element.hasOwnProperty(key) && element[key]?.hasOwnProperty('undefined')) {
                                element[key] = {
                                    ...element[key],
                                    base64content: await convertFileToBase64(element[key].rawFile),
                                    undefined: null
                                };
                            }
                        }
                    }
                }
            }
        }
    }

    console.log('PARAMS2', params);
    for (const property in params.data) {
        console.log(`${property}: ${params.data[property]}`);
    }
    console.log('THUMBNAIL', params.data.thumbnail);

    return requestHandler(type, resource, params);
};


export const dataProvider = (apiUrl: string, httpClient = fetchUtils.fetchJson) => {
    const composeFilter = (paramsFilter: any) => {

        if (typeof paramsFilter === 'undefined' || paramsFilter === '' || (typeof paramsFilter.q !== 'undefined' && paramsFilter.q === '')) {
            paramsFilter = {};
        }

        const flatFilter = fetchUtils.flattenObject(paramsFilter);

        const filter = Object.keys(flatFilter).map(key => {
            const splitKey = key.split('||');
            const ops = splitKey[1] ? splitKey[1] : 'cont';
            let field = splitKey[0];

            if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
                field = field.split(/\.(.+)/)[1];
            }
            return { field, operator: ops, value: flatFilter[key] };
        });
        return filter;
    };

    const convertDataRequestToHTTP = (type: string, resource: string, params: any) => {
        let url = '';
        const options: any = {};
        switch (type) {
            case GET_LIST: {
                params = params || {};
                if (params && params?.filter?.selectedIds) {
                    params.selectedIds = params.filter.selectedIds;
                    delete params.filter.selectedIds;
                }

                const { page, perPage } = params.pagination || {};

                // fix sorting when a dot is in the field's name
                if (params.sort) {
                    params.sort.field = params.sort.field.replace(/\./g, '');
                }

                let query = RequestQueryBuilder.create();

                // if filter is in an or ({ "or": [...]}),
                // the filters inside the [...] list will be put into or instead of filter
                // NOTE: if both filter and or exists it will be interpreted as
                // (filter1 AND filter2 AND ...) OR (or1 AND or2 AND ...)
                // this is the uniqueness of nestJS crud :(
                if ((params.filter || {}).or) {
                    const or: any[] = [];
                    params.filter.or.forEach((item: any) => {
                        composeFilter(item).forEach((it) => {
                            or.push(it);
                        });
                    });
                    delete params.filter.or;
                    query = query.setOr(or);
                }

                // make search usable (if there is search filter and or will be ignored)
                if ((params.filter || {}).search) {
                    query = query.search(params.filter.search);
                } else {
                    query = query.setFilter(composeFilter(params.filter) as unknown as QueryFilter);
                }

                if (perPage) {
                    query = query.setLimit(perPage);
                }

                if (page) {
                    query = query.setPage(page);
                }

                if (params.sort) {
                    query = query.sortBy(params.sort);
                }

                if (page && perPage) {
                    query = query.setOffset((page - 1) * perPage);
                }

                const queryUrl = query.query().replace('sort%5B0%5D=', 'sort=')
                .replace('filter%5B0%5D=', 'filter=')
                .replace(/filter%5B[0-9]%5D=/g, 'filter=')
                .replace(/cont%7C%7Ctrue/g, 'eq%7C%7Ctrue')
                .replace(/cont%7C%7Cfalse/g, 'eq%7C%7Cfalse')
                .replace('tags.id%7C%7Ccont', 'tags.id%7C%7Ceq')
                .replace('source.id%7C%7Ccont', 'source.id%7C%7Ceq')
                .replace(/createdAtMin%7C%7Ccont/g, 'createdAt||gte')
                .replace(/createdAtMax%7C%7Ccont/g, 'createdAt||lte')
                .replace(/minNewsUsage%7C%7Ccont/g, 'usageByNews||gte')
                .replace(/maxNewsUsage%7C%7Ccont/g, 'usageByNews||lte');

                url = `${apiUrl}/${resource}?${queryUrl}`;

                break;
            }
            case GET_ONE: {
                url = `${apiUrl}/${resource}/${params.id}`;

                break;
            }
            case GET_MANY: {
                const query = RequestQueryBuilder
                    .create()
                    .setFilter({
                        field: 'id',
                        operator: CondOperator.IN,
                        value: `${params.ids}`
                    })
                    .query()
                    .replace('filter%5B0%5D=', 'filter=');

                url = `${apiUrl}/${resource}?${query}`;

                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const filter = composeFilter(params.filter);

                filter.push({
                    field: params.target,
                    operator: CondOperator.EQUALS,
                    value: params.id
                });

                const query = RequestQueryBuilder
                    .create({
                        filter: filter as unknown as QueryFilter
                    })
                    .sortBy(params.sort)
                    .setLimit(perPage)
                    .setOffset((page - 1) * perPage)
                    .query().replace('sort%5B0%5D=', 'sort=')
                    .replace('filter%5B0%5D=', 'filter=')
                    .replace(/filter%5B[0-9]%5D=/g, 'filter=')
                    .replace(/cont%7C%7Ctrue/g, 'eq||true')
                    .replace(/cont%7C%7Cfalse/g, 'eq||false')
                    .replace(/createdAtMin%7C%7Ccont/g, 'createdAt||gte')
                    .replace(/createdAtMax%7C%7Ccont/g, 'createdAt||lte')
                    .replace(/minNewsUsage%7C%7Ccont/g, 'usageByNews||gte')
                    .replace(/maxNewsUsage%7C%7Ccont/g, 'usageByNews||lte');

                url = `${apiUrl}/${resource}?${query}`;

                break;
            }
            case UPDATE: {
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PATCH';
                options.body = JSON.stringify(params.data);
                break;
            }
            case CREATE: {
                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            }
            case DELETE: {
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }

        return { url, options };
    };

    const convertHTTPResponse = (response: any, type: string, resource: string, params: any) => {
        const { json } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (Array.isArray(json)) {
                    return {
                        data: json,
                        total: json.length
                    };
                }

                return {
                    data: json.data,
                    total: json.total
                };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            default:
                return { data: json };
        }
    };


    return (type: string, resource: string, params: any) => {

        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map((id: any) => httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data)
                }))
            )
                .then(responses => ({
                    data: responses.map((response: any) => response.json)
                }));
        }
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map((id: any) => httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE'
                }))
            ).then(responses => ({
                data: responses.map((response: any) => response.json)
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource !== 'pictures' ? resource : 'picture',
            params
        );
        return httpClient(url, options)
            .then((response: any) => convertHTTPResponse(response, type, resource, params));
    };
};
