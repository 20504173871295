import * as React from 'react';
import { ChipField } from 'react-admin';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { postWithAuth } from '../connection/fetch-with-auth';
import { Link } from '@material-ui/core';
import ConfirmDialog from './confirm-dialog';
import { useState } from 'react';
import { useRefresh } from 'react-admin';

export const OwnChipField = (props: any) => {
    const {newsId, ...rest} = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [removed, setRemoved] = useState(false);
    const refresh = useRefresh()
    const deleteTag = async ()=>{
        const res = await postWithAuth(`${rest.apiResource || 'news-admin'}/removeTag`, {'tagId': rest.record.id, 'newsId': newsId});
        if(res.status===201 || res.status===200) {
            setRemoved(true);
        }
    }
    const setDialog = ()=>{
        setDialogOpen(true);
    }
    const call = async ()=>{
        const res = await postWithAuth(`${rest.callApi}`, {'tagId': rest.record.id});
        if(res.status===201 || res.status===200) {
            refresh();
        }
    }
    if(removed) return <></>;
    return <div style={{display: 'flex', alignItems: 'center', marginRight: '7px'}}>
        <ConfirmDialog confirm={deleteTag} cancel={()=>{}} state={dialogOpen} setState={setDialogOpen} message={`A ${newsId} id-jú ${rest.apiResource || 'hír'}-ről letörölni a ${rest.record?.name} tag-et`}/>
        <Link onClick={setDialog}><HighlightOffRoundedIcon/></Link>
        <ChipField {...rest} onClick={()=>{
            if(rest.callApi){
                call();
            }
        }}/> 
    </div>
}

export default OwnChipField;