import * as React from 'react';
import {useState} from 'react';
import {AutocompleteInput, ReferenceInput, useRefresh} from 'react-admin';
import {postWithAuth} from '../connection/fetch-with-auth';
import {Link} from '@material-ui/core';
import ConfirmDialog from './confirm-dialog';
import AddIcon from '@material-ui/icons/Add';
import {Form} from 'react-final-form';

export const AddNewTagField = (props: any) => {
    return <div style={{display: 'flex', alignItems: 'center', marginRight: '7px'}} className={props.className}>
        <FormForTag newsId={props.newsId}/>
    </div>

}


const FormForTag =  (props:any) => {
    const [addTagId, setAddTagId] = useState(null);
    const [addTagName, setAddTagName] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const refresh = useRefresh()
    const submit = (formData:any)=>{
        setDialogOpen(true);
    }
    const addTag = async ()=>{
        const res = await postWithAuth('news-admin/addTag', {'tagId': addTagId, 'newsId': props.newsId});
        if(res.status===201 || res.status===200) {
            refresh();
        }
    }
    const onChangeValue = (value:any)=>{
        setAddTagId(value);
    }
    const renderMe = (data: any) => {
        if (data) {
            if(addTagId && data.id===addTagId){
                setAddTagName(data.name);
            }
            return data.name;
        }
        return '';
    };
    return (
        <Form
            onSubmit = {submit}
            {...props}
            render={(formProps:any) => (
                // here starts the custom form layout
                <form>
                    <ConfirmDialog confirm={addTag} cancel={()=>{}} state={dialogOpen} setState={setDialogOpen} message={`A ${props.newsId} id-jú hírre felrakni a ${addTagName} tag-et`}/>
                    <div style={{display:'flex', alignItems: 'center'}}>
                    <ReferenceInput 
                        source="newTag"
                        reference="tag-admin"
                        label="Tag hozzáadás"
                        filterToQuery={(searchText: any) => ({ 'name': searchText || '%' })}
                        fullWidth
                        allowEmpty={true}
                        emptyValue={null}
                        emptyText={''}
                        onChange={onChangeValue}>
                        <AutocompleteInput optionValue="id" optionText={renderMe}
                                label=""
                                shouldRenderSuggestions={(val: any) => {
                                    return val.trim().length > 2;
                                }}
                                allowEmpty={true}
                                emptyValue={null}
                                emptyText={''}
                               />
                    </ReferenceInput>
                    {addTagId && <Link onClick={(event:any)=>{submit(formProps)}} style={{height: '35px', marginLeft:'7px'}}>
                        <AddIcon style={{height: '35px', width: '35px'}}/>
                    </Link>}
                    </div>
                </form>
            )}
        />
    );
}
export default AddNewTagField;
