import React from 'react';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { Filter } from 'react-admin';

const renderName = (data: any) => {
    if (data) {
        return data.name;
    }
    return '';
};

const types=[
    {
        name: "Hasonló tag név",
        id: "tag-name",
    },
    {
        name: "Egyező kulcsszavak",
        id: "kewwords",
    },
]

export const TagSuggestionFilter = (props:any) => (
    <Filter {...props}>
        <SelectInput source='suggestionType' label="Ajánlás típusa" choices={types}/>
        <ReferenceInput 
                        source='tagsToMerge.id'
                        reference="tag-admin"
                        label="Tag"
                        filterToQuery={(searchText: any) => ({ 'name': searchText || '%' })}
                        fullWidth
                        allowEmpty={true}
                        emptyValue={null}
                        emptyText={''}>
                        <AutocompleteInput optionValue='id' optionText={renderName}
                                label=""
                                allowEmpty={true}
                                emptyValue={null}
                                emptyText={''}
                               />
        </ReferenceInput>
    </Filter>
);