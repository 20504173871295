import {BooleanField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin';
import React from 'react';
import {RolesField} from './roles-field';
import {CustomShowActions} from "../../layout/custom-show-actions";
import {Role} from "../../utils/role";
import {rolesTree} from "../../utils/admin-roles";

export const AdminUserShow = ({ ...props }: any) => {

    return <Show {...props} title="Admin Felhasználó" actions={<CustomShowActions role={Role.AdminUserEdit}/>}>
        <TabbedShowLayout>
            <Tab label="Adatok">
                <TextField source="id" label="ID"/>
                <TextField source="name" label="Név"/>
                <TextField source="email" label="Email cím"/>
                <BooleanField source="isActive" label="Aktív"/>
            </Tab>
            <Tab label="Jogosultságok" path="roles">
                <RolesField rolesTree={rolesTree}/>
            </Tab>
        </TabbedShowLayout>
    </Show>;
};
