import React, {useState} from 'react';
import {Filter, List, TextInput, useListContext,} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {EditModal} from "./tag-picture-edit";
import {TagCard} from "./tag-card";


export const TagPictureList = (props: any) => {
    const newProps = {
        ...props,
        resource: "tag-admin",
    }

    return <List {...newProps}
                 title="Tag képek"
                 exporter={false}
                 perPage={81}
                 filters={<TagPictureFilter/>}
    >
        <TagPictureDataGrid/>
    </List>
}

const TagPictureFilter = (props: any) => {
    return <Filter {...props}>
        <TextInput label="Név" source="name"/>
    </Filter>
}

const useStyles = makeStyles(() => ({
    listRoot: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap'
    },
}));

const TagPictureDataGrid = () => {
    const {ids, data} = useListContext();
    const classes = useStyles();
    const [selectedTag, setSelectedTag] = useState<number>(0)
    const closeDialog = () => {
        setSelectedTag(0)
    }

    return <div className={classes.listRoot}>
        {ids.map((id: number) => {
            const tag = data[id];

            return <TagCard onClick={() => setSelectedTag(id)}
                            picture={tag.picture}
                            tag={tag}/>;
        })}
        <EditModal
            open={selectedTag !== 0}
            handleClose={closeDialog}
            tag={data[selectedTag]}
            setTag={(tagId: number = 0) => setSelectedTag(tagId)}
        />
    </div>
}
