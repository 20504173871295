import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField } from 'react-admin';
import get from 'lodash/get';

export const EnumField = (props: any) => {
    const translatorFunction = props.translatorFunction;
    return <FunctionField {...props} render={() => translatorFunction(get(props.record, props.source))}/>;
};

EnumField.propTypes = {
    translatorFunction: PropTypes.func,
    source: PropTypes.any,
    label: PropTypes.any,
    addLabel: PropTypes.any,
    record: PropTypes.any
};
