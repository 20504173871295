import { config } from './config';
import jwtDecode from 'jwt-decode';

export const authProvider = {
    login: async ({ username, password }: { username: string, password: string }) => {
        const request = new Request(`${config.apiUrl}/auth/admin-login`, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        const response = await fetch(request);

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        const token = await response.json();
        localStorage.setItem(JWT_TOKEN_LOCAL_STORAGE_KEY, token.access_token);
    },
    logout: async (params: any) => {
        localStorage.removeItem(JWT_TOKEN_LOCAL_STORAGE_KEY);
    },
    checkAuth: async (params: any) => {

        if (!localStorage.getItem(JWT_TOKEN_LOCAL_STORAGE_KEY)) {
            throw new Error('Not Authenticated');
        }

        const request = new Request(`${config.apiUrl}/auth/admin-login-check`, {
            method: 'POST',
            headers: new Headers({ 'Authorization': getJwtHeader() })
        });
        const response = await fetch(request);

        if (response.status >= 400) {
            throw new Error('Not Authenticated');
        }

        return true;
    },
    checkError: (error: any) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem(JWT_TOKEN_LOCAL_STORAGE_KEY);
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: async (params: any) => {
        const jwtData = getJwtData();

        return (jwtData || {}).roles || [];
    }
};

export const getJwtData = (): any => {
    const token = localStorage.getItem(JWT_TOKEN_LOCAL_STORAGE_KEY);

    if (!token) {
        return null;
    }

    return jwtDecode(token);
};

export const getJwtHeader = (): string => {
    const token = localStorage.getItem(JWT_TOKEN_LOCAL_STORAGE_KEY);

    return `Bearer ${token}`;
};

export const JWT_TOKEN_LOCAL_STORAGE_KEY = 'jwt-token';
