import React from 'react';
import {
    List,
} from 'react-admin';
import { NewsDataGrid } from '../../layout/newsgrid';
import { ListActionsWithSortButton } from '../../fields/actions-sort-button';
import { NewsBulkActions } from './news-bulk-actions';
import { NewsFilter } from './source-filters';

export const NewsList = (props: any) => {

    return <List {...props} 
                actions={<ListActionsWithSortButton sortFields={['title', 'id', 'createdAt']}/>}
                bulkActionButtons={
                    <NewsBulkActions/>}
                title="Hírek"
                 filters={<NewsFilter/>}
                 exporter={false}
        >
            <NewsDataGrid/>
    </List>
}
