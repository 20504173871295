import React from "react";
import { TextInput } from "react-admin";
import { BooleanInput } from "react-admin";
import {BooleanField, Datagrid, Filter, List, TextField} from "react-admin";

export const BlockedKeywordsList = (props: any) => {
    return <List
        {...props}
        title="Blokkolt kulcsszavak"
        exporter={false}
        filters={<BlockedKeywordsFilter/>}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID"/>
            <TextField source="keywordsRegex" label="Kulcsszó regex"/>
            <BooleanField source="active" label="Aktív"/>
        </Datagrid>
    </List>
};

const BlockedKeywordsFilter = (props: any) => {
    return <Filter {...props}>
        <TextInput source="keywordsRegex" label="Kulcsszó regex"/>
        <BooleanInput source="active" label="Aktív"/>
    </Filter>
}
