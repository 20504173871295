import React from 'react';
import {Admin, fetchUtils} from 'react-admin';
import {config} from "./connection/config";
import {authProvider, getJwtHeader} from "./connection/auth-provider";
import {addUploadFeature, dataProvider} from "./connection/data-provider";
import {
    getCurrentTheme,
    getStyle,
    LOCAL_STORAGE_DARK_MODE,
    LOCAL_STORAGE_THEME_COLOR,
    THEME_COLOR_BLUE,
    THEME_LIGHT,
    ThemeSettings,
    useDarkMode
} from "./layout/theme-settings";
import useLocalStorage from "react-use-localstorage";
import {CustomLayout} from "./layout/custom-layout";
import Menu from "./layout/menu";
import {Resource} from "ra-core";
import {AdminUserList} from "./resource/admin-user/admin-user-list";
import {AdminUserShow} from "./resource/admin-user/admin-user-show";
import {AdminUserEdit} from "./resource/admin-user/admin-user-edit";
import {AdminUserCreate} from "./resource/admin-user/admin-user-create";
import {NewPasswordForm} from "./layout/new-password-form";
import {Route} from 'react-router-dom';
import {TopicList} from "./resource/topic/topic-list";
import {TopicEdit} from "./resource/topic/topic-edit";
import {NewsList} from './resource/news/news-list';
import {NewsShow} from './resource/news/news-show';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import hungarianMessages from 'ra-language-hungarian';
import {injectCustomMessages} from './utils/admin-custom-messages';
import {SourceList} from './resource/source/source-list';
import {SourceCreate} from './resource/source/source-create';
import {SourceEdit} from './resource/source/source-edit';
import {TagMergeList} from './resource/topic/tag-merge-list';
import { TagSuggestedMergeList } from './resource/topic/tag-suggested-merge/tag-suggested-list';
import {LoginPage} from "./layout/login-page";
import {TagPictureList} from "./resource/topic/tag-picture/tag-picture-list";
import { TopNewsBlocklistList } from './resource/top-newsblocklist/top-news-blocklist-list';
import {TopNewsBlocklistEdit} from "./resource/top-newsblocklist/top-news-blocklist-edit";
import {TopNewsBlocklistCreate} from "./resource/top-newsblocklist/top-news-blocklist-create";
import FacebookSettings from "./resource/facebook/facebook-settings";
import {BlockedKeywordsList} from "./resource/blocked-keywords/blocked-keywords-list";
import {BlockedKeywordsEdit} from "./resource/blocked-keywords/blocked-keywords-edit";
import {BlockedKeywordsCreate} from "./resource/blocked-keywords/blocked-keywords-create";

const messages = {
   'hu': injectCustomMessages(hungarianMessages)
 };

 const i18nProvider = polyglotI18nProvider((locale: string) => messages['hu'], 'hu');

const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    options.headers.set('Authorization', getJwtHeader());
    return fetchUtils.fetchJson(url, options);
};

const configuredDataProvider = addUploadFeature(dataProvider(config.apiUrl, httpClient));

function App() {
    const darkMode = useDarkMode();
    const [, setDarkMode] = useLocalStorage(LOCAL_STORAGE_DARK_MODE, THEME_LIGHT);
    const [, setThemeColor] = useLocalStorage(LOCAL_STORAGE_THEME_COLOR, THEME_COLOR_BLUE);
    const currentThemeObject = getCurrentTheme();

    let currentTheme = currentThemeObject.light;
    if (darkMode) {
        currentTheme = currentThemeObject.dark;
    }

    return (
        <Admin dataProvider={configuredDataProvider}
               authProvider={authProvider}
               loginPage={LoginPage}
               theme={getStyle(currentTheme)}
               i18nProvider={i18nProvider}
               layout={(data: any) => CustomLayout({...data, menu: Menu})}

            // customSagas={[selectedIdsSaga]}


               customRoutes={[
                   <Route
                       key="theme-settings"
                       path="/theme-settings"
                       render={(routeProps: any) => <ThemeSettings {...routeProps} setThemeColor={setThemeColor}
                                                                   setDarkMode={setDarkMode}/>}
                   />,
                   <Route
                       key="new-password"
                       path="/new-password"
                       render={(routeProps: any) => <NewPasswordForm {...routeProps}/>}
                   />
               ]}
               dashboard={(_props: any) => <ThemeSettings {..._props} setThemeColor={setThemeColor}
                                                          setDarkMode={setDarkMode}/>}
        >

            <Resource name="admin"
                list={AdminUserList}
                show={AdminUserShow}
                edit={AdminUserEdit}
                create={AdminUserCreate}
            />
            <Resource name="tag-admin"
                list={TopicList}
                edit={TopicEdit}
            />
            <Resource name="tag-merge-admin"
                list={TagMergeList}
            />
            <Resource name="news-admin"
                list={NewsList}
                show={NewsShow}
            />
            <Resource name="tag-suggested-merge-admin"
                list={TagSuggestedMergeList}
            />
            <Resource name="source-admin"
                list={SourceList}
                create={SourceCreate}
                edit={SourceEdit}
            />
            <Resource name="tag-pictures"
                      list={TagPictureList}
            />
            <Resource name="top-news-blocklist"
                      list={TopNewsBlocklistList}
                      edit={TopNewsBlocklistEdit}
                      create={TopNewsBlocklistCreate}
            />
            <Resource name="blocked-keywords-admin"
                      list={BlockedKeywordsList}
                      edit={BlockedKeywordsEdit}
                      create={BlockedKeywordsCreate}
            />
            <Resource name="facebook"
                      list={FacebookSettings}
                      />
        </Admin>
    );
}

export default App;
