import React from 'react';
import { BulkEditType } from '../../../fields/bulk-edit-fields/bulk-edit';
import { BulkEditButton } from '../../../fields/bulk-edit-fields/edit-button';

export const TagSuggestionBulkActions = ( props:any) => {
    return <>
        <BulkEditButton selectedIds={props.selectedIds}
            resource='tag-suggested-merge-admin'
            source='activate'
            buttonLabel="Futtatás"
            dialogLabel="Kiválasztott összeolvasztások lefuttatása"
            editLabel={'Össyeolvasztás/Törlés'}
            bulkEndpoint='saveMore'
            type={BulkEditType.boolean}/>
        </>
}