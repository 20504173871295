import React, {useEffect, useState} from "react";
import {getWithAuth, postWithAuth} from "../../../connection/fetch-with-auth";
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {TagCard} from "./tag-card";
import {makeStyles} from "@material-ui/core/styles";
import {useNotify, useRefresh} from "ra-core";

const useStyles = makeStyles(() => ({
    urlEdit: {
        marginBottom: 10,
        width: 500
    },
    editTagPic: {
        marginBottom: 20,
    },
    recomendations: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    saveButton: {
        textAlign: 'end'
    }
}));

export const EditModal = (props: any) => {
    const {open, handleClose, tag} = props;
    const [imageUrl, setImageUrl] = useState<string>('');
    const classes = useStyles();
    const [recomendedImages, setRecomendedImages] = useState([])
    const [selectedImage, setSelectedImage] = useState<string>('')
    const refresh = useRefresh();
    const notify = useNotify();

    useEffect(() => {
        if (selectedImage) {
            setImageUrl(selectedImage)
        }
    }, [selectedImage])

    useEffect(() => {
        if (tag?.picture) {
            setImageUrl(tag.picture);
        }

        if (tag?.id) {
            setRecomendedImages([])
            setSelectedImage('')
            getWithAuth(`tag-admin/${tag?.id}/suggestedPictures`)
                .then((res: any) => {
                    setRecomendedImages(res.data)
                })
        }
    }, [tag]);

    const handleSave = () => {
        postWithAuth(`tag-admin/${tag?.id}/setPicture`, {
            picture: imageUrl
        })
            .then(() => {
                props?.handleClose();
                refresh();
            })
            .catch(e => {
                console.log(e);
                notify(e.message)
            })
    }

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{tag?.name}</DialogTitle>
        <DialogContent>


            <TextField
                className={classes.urlEdit}
                value={imageUrl}
                placeholder="Kép url"
                onChange={e => setImageUrl(e.target.value)}/>


            <br/>
            <img
                className={classes.editTagPic}
                src={selectedImage || tag?.picture}
                width={300}
                alt={tag?.name}/>

            <div className={classes.saveButton}>
                <Button color="primary" variant="contained" onClick={handleSave}>
                    Mentés
                </Button>
            </div>

            <div>Ajánlások:</div>

            <div className={classes.recomendations}>
                {recomendedImages?.length > 0 && recomendedImages.map(image =>
                    <TagCard onClick={() => setSelectedImage(image)}
                             picture={image}
                             tag={tag}/>
                )}
            </div>

        </DialogContent>
    </Dialog>
}
