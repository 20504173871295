import axios from 'axios';
import { config } from './config';
import { getJwtHeader } from './auth-provider';

const endpoint = config.apiUrl;

export const getWithAuth = async (path: string) => {
    return axios.get(`${endpoint}/${path}`, {
        headers: { Authorization: getJwtHeader()  }
    });
};

export const putWithAuth = async (path: string, data: {}) => {
    return axios.put(`${endpoint}/${path}`, data, {
        headers: { Authorization: getJwtHeader() }
    });
};

export const postWithAuth = async (path: string, data: {}) => {
    return axios.post(`${endpoint}/${path}`, data, {
        headers: { Authorization: getJwtHeader() }
    });
};
