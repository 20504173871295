import React from 'react';
import get from 'lodash/get';

interface Props extends React.HTMLProps<any> {
    source?: string;
    record?: any;
    height?: string;
    noHeight?: boolean;
}

export const PictureField: React.FunctionComponent<Props> = (props: Props) => {
    const picture = (props.source ? get(props.record, props.source) : props.record) || null;

    const height = props.height || '200px';

    const style = props.style || {};
    let heightStyle = {};
    if (!props.noHeight) heightStyle = { height: height };
    const styleWithHeight = {
        ...style,
        ...heightStyle
    };


    if (!picture) {
        return <img style={styleWithHeight} src="" alt="Kép"/>;
    }

    // this happens when you add a picture to a PictureInput
    const src = picture.undefined;

    if (!src) {
        return <img src="" style={styleWithHeight} alt="Kép"/>;
    }

    return <img style={styleWithHeight} src={picture.undefined} alt="Kép"/>;
};
