import React from "react";
import {
    Create,
} from "react-admin";
import { AdminUserRolesForm, BaseAdminUserForm } from './admin-user-edit';
import {EditWithoutDelete} from "../../layout/edit-without-delete";
import {rolesTree} from "../../utils/admin-roles";

export const AdminUserCreate = ({ ...props }: any) => {
    return <Create {...props} title="Új admin felhasználó">
        <EditWithoutDelete>
            <BaseAdminUserForm path="" tabLabel="adatok"/>
            <AdminUserRolesForm path="roles" tabLabel="Jogosultságok" rolesTree={rolesTree}/>
        </EditWithoutDelete>
    </Create>
}
