import React, {Children, useState} from 'react';
import {Box, Paper, Tab, Tabs} from '@material-ui/core';
import {Link, useLocation, useRouteMatch} from 'react-router-dom';
import {DeleteButton, FormWithRedirect, SaveButton, Toolbar} from 'react-admin';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const TabPanel = ({ index, currentTab, children }: any) => {
    return <div hidden={index !== currentTab}>
        <Box>
            {children}
        </Box>
    </div>;
};


const getTabFullPath = (tab: any, index: any, baseUrl: any) =>
    `${baseUrl}${
        tab.props.path ? `/${tab.props.path}` : index > 0 ? `/${index}` : ''
    }`;

export const EditWithoutDelete = ({ hideTabs = false, needConfirm = false, confirmLabel = 'Biztosan mented a változásokat?', ...props }: any) => {

    const [error, setError] = useState(false);

    const location = useLocation();
    const match = useRouteMatch();

    const value = location.pathname;
    const children = props.children?.filter((c: any) => c) || [props.children];
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    return <FormWithRedirect
        {...props}
        render={(formProps: any) =>
            <form>
                <Paper>
                    <div>
                        {!hideTabs && <Tabs value={value}
                                            // onChange={(_, tab) => setCurrentTab(tab)}
                                            indicatorColor="primary"
                                            textColor="primary">
                            {Children.map(children, (child, index) => {
                                if (child?.props?.disabled) return <></>;
                                const tabPath = getTabFullPath(child, index, match.url);
                                return <Tab to={tabPath} component={Link} value={tabPath} /*context="header"*/
                                            label={child.props.tabLabel}/>;
                            })}
                        </Tabs>
                        }
                        <Box p="1.5em">
                            {Children.map(children, (child, index) => {
                                if (child?.props?.disabled) return <></>;
                                const tabPath = getTabFullPath(child, index, match.url);

                                return <TabPanel currentTab={value} index={tabPath}>
                                    {React.cloneElement(child, props)}
                                </TabPanel>;
                            })}
                        </Box>
                    </div>
                    <Toolbar>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            {needConfirm ?
                                (
                                    <>
                                        <SaveButton
                                            onClick={() => setConfirmDialogOpen(true)}
                                            saving={false}
                                            handleSubmitWithRedirect={() => {
                                            }}
                                            disabled={formProps.disableSaveButton}
                                        />
                                        <Dialog
                                            open={confirmDialogOpen}
                                            onClose={() => setConfirmDialogOpen(false)}
                                        >
                                            <DialogTitle>
                                                {confirmLabel}
                                            </DialogTitle>
                                            <DialogActions>
                                                <SaveButton
                                                    onClick={() => {
                                                        setConfirmDialogOpen(false);
                                                        setError(formProps.invalid);
                                                    }}
                                                    saving={formProps.saving}
                                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                                    disabled={formProps.disableSaveButton}
                                                />
                                                <Button color="primary" onClick={() => setConfirmDialogOpen(false)}>
                                                    Mégse
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                ) : (
                                    <SaveButton
                                        onClick={() => setError(formProps.invalid)}
                                        saving={formProps.saving}
                                        handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                        disabled={formProps.disableSaveButton}
                                    />
                                )
                            }
                            {!formProps.valid && error &&
                            <Alert style={{ position: 'relative' }} severity="error">Hiányzó adatok! Elképzelhető, hogy
                                a hiba egy
                                másik fülön van!</Alert>}
                            {formProps.disableSaveButton &&
                            <Alert style={{ position: 'relative' }} severity="error">Hiányzó adatok! Előkép hozzáadása
                                kötelező!
                            </Alert>}
                            {props.isDeletable && <DeleteButton record={formProps.record}/>}
                        </Box>
                    </Toolbar>
                </Paper>
            </form>
        }/>;
};
