import {Button} from '@material-ui/core';
import React, {cloneElement} from 'react';
import {TopToolbar, useListContext} from 'react-admin';

export const TopicActionButton = (props:any) => {
    const {
        treeView,
        filters,
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        showFilter,
    } = useListContext();

    const changeMode= ()=>{
        if(props?.setTreeView){
            props.setTreeView(!treeView);
        }
    } 

    return ( 
        <TopToolbar>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button onClick={(event:any)=>changeMode()}>
                {treeView?'Lista nézet':'fa nézet'}
            </Button>
        </TopToolbar>
    );
}
