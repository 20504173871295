import React, { useCallback, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import { BulkEdit, BulkEditType } from './bulk-edit';
interface BulkEditButtonProps{
    resource: string;
    source: string;
    selectedIds: number[];
    buttonLabel: string;
    dialogLabel: string;
    needConfirm?: boolean;
    bulkEndpoint: string;
    editLabel?: string;
    reference?: string;
    type: BulkEditType;
}
export const BulkEditButton = (props: BulkEditButtonProps) => {

    const [showBulkEditDialog, setShowBulkEditDialog] = useState(false);

    const handleNewClick = useCallback(
        event => {
            event.preventDefault();
            setShowBulkEditDialog(true);
        },
        [setShowBulkEditDialog]
    );

    const handleCloseCreate = useCallback(() => {
        setShowBulkEditDialog(false);
    }, [setShowBulkEditDialog]);

    const handleSave = useCallback(
        post => {
            setShowBulkEditDialog(false);
        },
        [setShowBulkEditDialog]
    );

    return (
        <React.Fragment>
            <Button variant="contained"
                    color="primary"
                    onClick={handleNewClick}
                    style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>
                {props.buttonLabel}
            </Button>
            <Dialog
                data-testid="dialog-edit-keywords"
                fullWidth
                open={showBulkEditDialog}
                onClose={handleCloseCreate}
                aria-label="Új címke"
                style={{zIndex: 2}}
            >
                <DialogTitle>{props.dialogLabel}</DialogTitle>
                <DialogContent style={{ padding: '0px' }}>
                    <BulkEdit
                        onCancel={handleCloseCreate}
                        onSave={handleSave}
                        selectedIds={props.selectedIds}
                        source={props.source}
                        needConfirm={props.needConfirm||false}
                        bulkEndpoint={props.bulkEndpoint}
                        resource={props.resource}
                        reference={props.reference||''}
                        editLabel={props.editLabel||''}
                        type={props.type}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};