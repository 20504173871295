import React from "react";
import {BooleanInput, Edit, SimpleForm, TextInput} from "react-admin";

export const BlockedKeywordsEdit = (props: any) => {
    return <Edit {...props} title="Blokkolt kulcsszó szerkesztése">
        <BlockedKeywordsForm {...props}/>
    </Edit>;
};

export const BlockedKeywordsForm = (props: any) => {
    return <SimpleForm {...props}>
        <BooleanInput source="active" label="Aktív" defaultValue={true}/>
        <TextInput source="keywordsRegex" label="Kulcsszó regex" fullWidth/>
        <div className="MuiTypography-body2">
            <a href="https://regex101.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }} className="MuiButton-textPrimary">
                Regex teszt
            </a>
        </div>
    </SimpleForm>
}
