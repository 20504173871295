import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Layout} from 'react-admin';
import {CustomAppBar} from './app-bar';

export const CustomLayout = (props: any) => {
    return <Layout style={{width: '100%', minWidth: 'auto'}} {...props} appBar={CustomAppBar} classes={useStyles()}/>;
};


// in src/MyLayout.js

const useStyles = makeStyles(theme => ({
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        [theme.breakpoints.up('xs')]: {
            marginTop: theme.spacing(6),
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(7),
        },
        width: '100%',
        overflow: 'auto',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: 0,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingLeft: 0,
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 5,
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
        width: '100%',
        overflow: 'auto',
    },
}));
