import React from 'react';
import {Card} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    onClick: () => void,
    picture: string,
    tag: any
}

export const TagCard = (props: Props) => {
    const classes = useStyles();
    const bgImage = props.picture ? `url("${props.picture}")` : ''

    return <Card className={classes.card} style={{
        backgroundImage: bgImage,
        backgroundColor: 'red'
    }}
                 onClick={props.onClick}
    >
        <div className={classes.darkBg}/>
        <div className={classes.tagName}>
            {props.tag?.name}
        </div>
    </Card>
}

const useStyles = makeStyles(() => ({
    card: {
        width: 160,
        height: 80,
        backgroundSize: '100% auto',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        margin: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '&.lazy': {
            backgroundImage: 'none',
        }
    },
    tagName: {
        color: 'white',
        zIndex: 1,
        pointerEvents: 'none'
    },
    darkBg: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        '&:hover': {
            background: 'rgba(0,0,0,0.5)'
        }
    },
}));
