import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface MyProps{
    confirm: ()=>any;
    cancel: ()=>any;
    message: string;
    state: boolean;
    setState: (state:boolean)=>any
}

class ConfirmDialog extends React.Component<MyProps> {
  handleClickOpen = () => {
    this.setState(true);
  };

  handleClose = () => {
    this.props.setState(false);
  };

  handleAgree = () => {
    this.props.confirm();
    this.handleClose();
  };
  handleDisagree = () => {
      this.props.cancel();
    this.handleClose();
  };
  render() {
    return (
      <div>
        {/* Dialog that is displayed if the state open is true */}
        <Dialog
          open={this.props.state}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Figyelem!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Biztosan végre akarod halytani a következő utasítást: <br/>
              {this.props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDisagree} color="primary">
              Nem
            </Button>
            <Button onClick={this.handleAgree} color="primary" autoFocus>
              Igen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmDialog;
