import React from 'react';
import { BooleanInput } from 'react-admin';
import { TextInput } from 'react-admin';
import { Filter } from 'react-admin';

export const SourceFilter = (props:any) => (
    <Filter {...props}>
        <TextInput label="Név" source="name"/>
        <BooleanInput label="Aktív" source="isActive" defaultValue={true}/>
    </Filter>
);