import React from 'react';
import {DateField} from 'react-admin';

export const HungarianDateField = (props: any) => {
    return <DateField {...props} locales="hu-HU" />
};

export const HungarianDateTimeField = (props: any) => {
    return <DateField {...props} showTime locales="hu-HU" />
};
