// TODO import huLocale from "date-fns/locale/hu";
import React from 'react';
import { TimeInput, DateTimeInput, DateInput } from 'react-admin-date-inputs';

export const HungarianDateInput = (props: any) => {
    return <DateInput
        {...props}
        options={{ format: 'yyyy-MM-dd' }}
        /* TODO providerOptions={{ locale: huLocale }} *//>;
};

export const HungarianTimeInput = (props: any) => {
    return <TimeInput
        {...props}
        options={{ format: 'hh:mm:ss' }}
        /* TODO providerOptions={{ locale: huLocale }} *//>;
};

export const HungarianDateTimeInput = (props: any) => {
    return <DateTimeInput
        {...props}
        options={{ format: 'yyyy-MM-dd hh:mm:ss' }}
        /* TODO providerOptions={{ locale: huLocale }} *//>;
};
