import {
    Checkbox,
    Collapse,
    IconButton, List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

const RolesInputItem =
    ({ item, viewChecked = null, setViewChecked, editChecked = null, setEditChecked, ...props }: any) => {

        const changeViewChecked = (isChecked: boolean) => {
            setViewChecked(isChecked);
        };
        const changeEditChecked = (isChecked: boolean) => {
            setEditChecked(isChecked);
        };

        return <ListItem>
            <ListItemText primary={item?.label}/>
            <ListItemIcon>
                {item?.view &&
                <FormControlLabel
                    control={
                        <Checkbox
                            edge="start"
                            checked={!!viewChecked}
                            disableRipple
                            onClick={() => changeViewChecked(!viewChecked)}
                            icon={<VisibilityIcon/>}
                            checkedIcon={<VisibilityIcon/>}
                        />
                    }
                    label=""
                />
                }
            </ListItemIcon>
            <ListItemIcon>
                {item?.edit &&
                <FormControlLabel
                    control={
                        <Checkbox
                            edge="start"
                            checked={!!editChecked}
                            disableRipple
                            onClick={() => changeEditChecked(!editChecked)}
                            icon={<EditIcon/>}
                            checkedIcon={<EditIcon/>}
                        />
                    }
                    label=""
                />
                }
            </ListItemIcon>
        </ListItem>;
    }
;

const RolesInputCollapsibleItem =
    ({ item, viewChecked = null, setViewChecked, editChecked = null, setEditChecked, checkedRoles, changeChecked, ...props }:
         any) => {

        const [isOpen, setIsOpen] = useState(false);

        const changeViewChecked = (isChecked: boolean) => {
            setViewChecked(isChecked);
        };

        return <React.Fragment>
            <ListItem>
                <ListItemText primary={item?.label}/>
                <ListItemIcon>
                    {item?.view &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                edge="start"
                                checked={!!viewChecked}
                                disableRipple
                                onClick={() => changeViewChecked(!viewChecked)}
                                icon={<VisibilityIcon/>}
                                checkedIcon={<VisibilityIcon/>}
                            />
                        }
                        label=""
                    />
                    }
                </ListItemIcon>
                <ListItemSecondaryAction onClick={() => setIsOpen(!isOpen)}>
                    <IconButton>
                        {isOpen ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <RolesInputView ml={20} rolesTree={item?.children} checkedRoles={checkedRoles}
                                changeChecked={changeChecked}/>
            </Collapse>
        </React.Fragment>;
    };

export const RolesInputView = ({ rolesTree, ml = 0, checkedRoles, changeChecked, firstView = false, ...props }: any) => {
    const {
        selectedAllView,
        setSelectedAllView,
        selectedAllEdit,
        setSelectedAllEdit,
        setAll,
        removeAll
    } = props;

    return <List style={{ marginLeft: `${ml}px` }} dense component="div" disablePadding>
        {firstView &&
        <ListItem>
            <ListItemText primary=" "/>
            <ListItemIcon>
                <FormControlLabel
                    control={
                        <Checkbox
                            edge="start"
                            checked={selectedAllView}
                            disableRipple
                            onClick={() => {
                                setSelectedAllView(!selectedAllView);
                                if (!selectedAllView)
                                    setAll('view');
                                else
                                    removeAll('view');
                            }}
                            icon={<VisibilityIcon/>}
                            checkedIcon={<VisibilityIcon/>}
                        />
                    }
                    label=""
                />
            </ListItemIcon>
            <ListItemIcon>
                <FormControlLabel
                    control={
                        <Checkbox
                            edge="start"
                            checked={selectedAllEdit}
                            disableRipple
                            onClick={() => {
                                setSelectedAllEdit(!selectedAllEdit);
                                if (!selectedAllEdit)
                                    setAll('edit');
                                else
                                    removeAll('edit');
                            }}
                            icon={<EditIcon/>}
                            checkedIcon={<EditIcon/>}
                        />
                    }
                    label=""
                />
            </ListItemIcon>
        </ListItem>
        }
        {rolesTree.map((item: any) => {
            if (item.children && item.children.length > 0) {
                return <RolesInputCollapsibleItem
                    item={item}
                    viewChecked={checkedRoles?.find((r: any) => r.name === item?.view)}
                    editChecked={checkedRoles?.find((r: any) => r.name === item?.edit)}
                    setViewChecked={(isChecked: boolean) => {
                        changeChecked(item?.view, isChecked);
                    }}
                    setEditChecked={(isChecked: boolean) => {
                        changeChecked(item?.edit, isChecked);
                    }}
                    checkedRoles={checkedRoles}
                    changeChecked={changeChecked}
                />;
            }

            return <RolesInputItem
                item={item}
                viewChecked={checkedRoles?.find((r: any) => r.name === item?.view)}
                editChecked={checkedRoles?.find((r: any) => r.name === item?.edit)}
                setViewChecked={(isChecked: boolean) => {
                    changeChecked(item?.view, isChecked);
                }}
                setEditChecked={(isChecked: boolean) => {
                    changeChecked(item?.edit, isChecked);
                }}
                changeChecked={changeChecked}
            />;
        })}
    </List>;
};

const gotAll = (checkedRoles: any, rolesTree: any, source: any): any => {
    const roles = checkedRoles?.map((r: any) => r.name);
    let gotThemAll = true;
    rolesTree.forEach((item: any) => {
        if (item?.hasOwnProperty(source)) {
            if (!roles?.includes(item[source])) {
                gotThemAll = false;
                return;
            }
        }
        item?.children?.forEach((_item: any) => {
            if (_item?.hasOwnProperty(source)) {
                if (!roles?.includes(_item[source])) {
                    gotThemAll = false;
                    return;
                }
            }
        });
    });

    return gotThemAll;
};

export const RolesInput = ({ input, rolesTree, ...props }: any) => {

    let checkedRoles = input.value || [];

    const [refresh, setRefresh] = useState(false);
    const [selectedAllView, setSelectedAllView] = useState(gotAll(checkedRoles, rolesTree, 'view'));
    const [selectedAllEdit, setSelectedAllEdit] = useState(gotAll(checkedRoles, rolesTree, 'edit'));


    const checkedChanged = (role: any, isChecked: boolean) => {
        if (isChecked) {
            checkedRoles.push({ id: 0, name: role });
            if (!selectedAllView && gotAll(checkedRoles, rolesTree, 'view'))
                setSelectedAllView(true);
            if (!selectedAllEdit && gotAll(checkedRoles, rolesTree, 'edit'))
                setSelectedAllEdit(true);
        } else {
            checkedRoles = checkedRoles.filter((r: any) => r.name !== role);
            if (selectedAllView && !gotAll(checkedRoles, rolesTree, 'view'))
                setSelectedAllView(false);
            if (selectedAllEdit && !gotAll(checkedRoles, rolesTree, 'edit'))
                setSelectedAllEdit(false);
        }
        input.onChange(checkedRoles);
        setRefresh(!refresh);
    };

    const setAll = (source: any) => {
        rolesTree?.forEach((item: any) => {
            if (item?.hasOwnProperty(source)) {
                const foundItem = checkedRoles.find((role: any) => role.name === item[source]);
                if (!foundItem)
                    checkedRoles.push({ id: 0, name: item[source] });
            }
            item?.children?.forEach((_item: any) => {
                if (_item?.hasOwnProperty(source)) {
                    const foundItem = checkedRoles.find((role: any) => role.name === _item[source]);
                    if (!foundItem)
                        checkedRoles.push({ id: 0, name: _item[source] });
                }
            });
        });
        input.onChange(checkedRoles);
        setRefresh(!refresh);
    };

    const removeAll = (source: any) => {
        rolesTree?.forEach((item: any) => {
            if (item?.hasOwnProperty(source)) {
                const foundItem = checkedRoles.find((role: any) => role.name === item[source]);
                if (foundItem)
                    checkedRoles.splice(checkedRoles.indexOf(foundItem), 1);
            }
            item?.children?.forEach((_item: any) => {
                if (_item?.hasOwnProperty(source)) {
                    const foundItem = checkedRoles.find((role: any) => role.name === _item[source]);
                    if (foundItem)
                        checkedRoles.splice(checkedRoles.indexOf(foundItem), 1);
                }
            });
        });
        input.onChange(checkedRoles);
        setRefresh(!refresh);
    };

    return <Grid container>
        <Grid item lg={4} md={8}>
            <RolesInputView rolesTree={rolesTree}
                            checkedRoles={checkedRoles}
                            changeChecked={checkedChanged}
                            firstView={true}
                            selectedAllView={selectedAllView}
                            setSelectedAllView={setSelectedAllView}
                            selectedAllEdit={selectedAllEdit}
                            setSelectedAllEdit={setSelectedAllEdit}
                            setAll={setAll}
                            removeAll={removeAll}
            />
        </Grid>
    </Grid>;
};
