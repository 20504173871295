import React, { cloneElement } from 'react';
import { CreateButton } from 'react-admin';
import { SortButton } from 'react-admin';
import { TopToolbar } from 'react-admin';
import { useListContext } from 'react-admin';

export const ListActionsWithSortButton = (props:any) => {
    const {
        filters,
        sortFields,
        addCreateButton
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    return ( 
        <TopToolbar>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <SortButton fields={sortFields}/>
            {addCreateButton&& <CreateButton basePath={basePath}/>}
        </TopToolbar>
    );
}
