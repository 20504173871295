import React from 'react';
import {AppBar} from 'react-admin';
import CustomUserMenu from './user-menu';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

export const CustomAppBarWithDocs = (props: any) => {
    const classes = useStyles();
    return <AppBar style={{ zIndex: '3000' }} {...props} userMenu={<CustomUserMenu/>}>
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        <div style={{ flex: '1' }}/>
    </AppBar>;
};
export const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return <AppBar style={{ zIndex: '3000' }} {...props} userMenu={<CustomUserMenu/>}>
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
    </AppBar>;
};
