import * as React from 'react';
import {useState} from 'react';
import {ReferenceField, SingleFieldList, useListContext, TextField} from 'react-admin';
import {Button, Card, CardActions, CardContent, Checkbox, makeStyles} from '@material-ui/core';
import {ReferenceObjectArrayField} from '../../../fields/reference-object-array-field';
import {OwnChipField} from '../../../fields/ownchipfield';
import { postWithAuth } from '../../../connection/fetch-with-auth';
import { useRefresh } from 'react-admin';
import { useNotify } from 'react-admin';
import { EnumField } from '../../../fields/enum.field';

const cardStyle = makeStyles(theme => ({
    card: {
        width: 'calc(33.33% - 1em)',
        minHeight: 300,
        margin: '0.5em',
        display: 'inline-block',
        verticalAlign: 'top',
        [theme.breakpoints.down('md')]: {  
            width: 'calc(50% - 1em)',
        },
        [theme.breakpoints.down('xs')]: {  
            width: 'calc(100% - 1em)',
        }
    },
    noPadding: {
        paddingTop: 0,
        paddingBottom: '7px',
    },
    flexbox:{
        display: 'flex'
    },
    checkBox: {
        alignSelf: 'start',
    },
    allSelect:{
        width: '100%',
        marginBottom: '10px',
    },
    title: {
        padding: 15,
        '& .MuiTypography-root': {
            fontSize: 16,
        }
    },
    note: {
        fontSize: 10,
    },
}));
export const SuggestionDataGrid = () => {
    const { ids, data, basePath, selectedIds, onToggleItem, onSelect, onUnselectItems } = useListContext();
    const [selectAll, setSelectAll] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    
    const styles = cardStyle();

    const getName = (index: any) => {
        switch (index) {
            case 'tag-name':
                return 'Hasonló tag név';
            case 'kewwords':
                return 'Egyező kulcsszavak';
            default: 
                return `MI VAN???:${index}`;
        }
    };
    const rerunSuggestionByName = () => {
        postWithAuth('tag/createSuggestions', {}).then(
            (data)=>{
                if(data.status === 201){
                    notify("Frissítés sikeresen befejeződött!", 'success');
                    refresh();
                }
                else {
                    notify("hiba történt!", 'error');
                }
            }
        ).catch(error=>{
            notify("hiba történt!", 'error');
        });
        //if(res.status===201 || res.status===200) {
        //    notify("Frissítés sikeresen befejeződött", 'success');
        //    refresh();
        //}
        //else {
        //    notify("Hiba történt", 'error');
       // }
    }
    
    return (
        <div style={{ margin: '1em' }}>
            <div className={styles.allSelect}>
                <Button onClick={rerunSuggestionByName}>
                    Ajánlás újrafuttatása (név alapján) (meg nem jó)
                </Button>
                <p>
                    Összes kijelölése
                <Checkbox
                    checked={selectAll}
                    onClick={() => {
                        if(selectAll){
                            onUnselectItems();
                            setSelectAll(false);
                        }else {
                            onSelect(ids);
                            setSelectAll(true);
                        }
                    }}
                />
                </p>
            </div>
        {ids.map((id:any) =>
            <Card key={id} className={styles.card}>
                <div className={styles.flexbox}>
                <div className={styles.title}>
                    <EnumField record={data[id]} source="suggestionType" label="Típus" translatorFunction={getName} addLabel/>
                </div>
                <div style={{flex: 1}}/>
                <Checkbox
                    className = {styles.checkBox}
                    disabled={data[id].selectable}
                    checked={selectedIds.includes(id)}
                    onClick={() => onToggleItem(id)}
                />
                </div>
                <CardContent className={styles.noPadding}>
                    <div className={styles.flexbox}>
                    <ReferenceObjectArrayField basePath={basePath} record={data[id]} arraySource="id" resource="tag-suggested-merge-admin" source="tagsToMerge" reference="tag-admin"
                                                label="tag-ek" link={false} fullWidth>
                        <SingleFieldList linkType={false}>
                            <OwnChipField source="name" newsId={id} apiResource='tag-suggested-merge-admin' callApi={`tag-suggested-merge-admin/chooseLead/${id}`}/>
                        </SingleFieldList>
                    </ReferenceObjectArrayField>
                    </div>
                </CardContent>
                <CardContent>
                    <div className={styles.note}>Összeolvasztás ebbe a tagbe: (klikk a fentiek közül egyre a megváltoztatáshoz)</div>
                    <ReferenceField basePath={basePath} record={data[id]} source="leadTag.id" reference="tag-admin" label = "összeolvasztás ebbe a tagbe">
                        <TextField record={data[id]} source="name" label = "összeolvasztás ebbe a tagbe"/>
                    </ReferenceField>
                </CardContent>
                <CardActions style={{ textAlign: 'right' }}>
                    <Button onClick={()=>{
                        postWithAuth(`tag-suggested-merge-admin/merge/${id}`, {}).then(
                            (data)=>{
                                if(data.status === 201){
                                    notify("Összeolvadás sikeres!", 'success');
                                    refresh();
                                }
                                else {
                                    notify("hiba történt!", 'error');
                                }
                            }
                        ).catch(error=>{
                            notify("hiba történt!", 'error');
                        });
                    }
                    }>
                        Összevonás
                    </Button>
                </CardActions>
            </Card>
        )}
        </div>
    );
};
