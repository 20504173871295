import React, { useEffect, useState } from 'react';
import { inflateToTree } from './utils';
import { Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { getJwtData } from '../../connection/auth-provider';
import { Role } from '../../utils/role';

const TreeFieldItem = ({ item, resource, defaultOpenState, displayName, addShow, addEdit, role = null, spanContent }: any) => {
    const { id, children } = item;
    const jwtData = getJwtData();

    const [open, setOpen] = React.useState(defaultOpenState);

    const handleClick = () => {
        setOpen(!open);
    };

    const icon = ((open && children && children.length > 0) ?
        <ExpandLess style={{ marginRight: '8px' }}
            color={(children && children.length > 0) ? 'inherit' : 'disabled'} /> :
        <ExpandMore style={{ marginRight: '8px' }}
            color={(children && children.length > 0) ? 'inherit' : 'disabled'} />
    );

    return <React.Fragment key={id}>
        <ListItem button onClick={handleClick}>
            {icon}
            <ListItemText primary={displayName(item)} /> <span>{spanContent(item)}</span>
            <ListItemSecondaryAction>
                {addShow(item) && <IconButton edge="end" aria-label="info" href={`/#/${resource}/${id}/show`}>
                    <InfoIcon />
                </IconButton>}
                {addEdit(item) && (!role || jwtData?.roles.includes(role)) && <IconButton edge="end" aria-label="edit" href={`/#/${resource}/${id}`} target="_blank">
                    <EditIcon />
                </IconButton>}
            </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={open}
            timeout="auto"
            unmountOnExit>
            <TreeFieldView tree={children.sort((a:any, b:any) => (a.order || 99999) - (b.order || 99999))} marginLeft={24} resource={resource} defaultOpenState={defaultOpenState} displayName={displayName} addShow={addShow} addEdit={addEdit} role={role} spanContent={spanContent}/>
        </Collapse>
    </React.Fragment>;
}

const TreeFieldView = ({ tree, marginLeft = 0, resource, defaultOpenState, displayName, addShow, addEdit, role, spanContent, ...props }:any) => {
    if (!tree || tree.length === 0) {
        return null;
    }

    const children = tree.map((item:any) => {
        return <TreeFieldItem key={item.id} item={item} resource={resource} defaultOpenState={defaultOpenState} displayName={displayName} addShow={addShow} addEdit={addEdit} role={role} spanContent={spanContent}/>;
    });

    return <List style={{ paddingLeft: marginLeft }} component="nav">
        {children}
    </List>;
};

export const TreeDatagrid: React.FunctionComponent<TreeDatagridProps>= (props) => {
    const [tree, setTree] = useState([]);


    useEffect(() => {
        if (!props.data || !props.ids) {
            return;
        }

        const choices = props.ids.map(id => props.data[id]);

        setTree(inflateToTree(choices));
    }, [props.data, props.ids]);

    return <TreeFieldView tree={tree} resource={props.resource} defaultOpenState={props.defaultOpenState} displayName={props.displayName} addShow={props.addShow} addEdit={props.addEdit} role={props.role || null} spanContent={props.spanContent}/>;
};

interface TreeDatagridProps {
    data?: any,
    ids?: Array<any>,
    resource?: any,
    defaultOpenState?: boolean,
    displayName?: (data: any) => string,
    addShow?: (data: any) => boolean,
    addEdit?: (data: any) => boolean,
    role?: Role | null,
    spanContent?: (data: any) => string,
}

const defaultProps: TreeDatagridProps = {
    data: null,
    ids: [],
    defaultOpenState: false,
    resource: null,
    displayName:(data)=>data.name,
    addShow: (data)=>false,
    addEdit: (data)=>false,
    spanContent:(data) => '',
    role: null,
}
TreeDatagrid.defaultProps = defaultProps;

TreeDatagrid.propTypes = {
    data: PropTypes.any,
    ids: PropTypes.array,
    resource: PropTypes.any,
    defaultOpenState: PropTypes.bool,
    displayName: PropTypes.func,
    addShow: PropTypes.func,
    addEdit: PropTypes.func,
    spanContent: PropTypes.func,
};

