import {IconButton, makeStyles} from '@material-ui/core';
import React, {useState} from 'react';
import {Datagrid, EditButton, FunctionField, List, Pagination, ReferenceField, TextField} from 'react-admin';
import BooleanImediateSaver from '../../fields/boolean-saver-input';
import {HungarianDateTimeField} from '../../fields/hungarial-date-field';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import {Role} from "../../utils/role";
import {TopicFilter} from './topic-filter';
import {TreeDatagrid} from '../../layout/tree/tree-datagrid';
import {TopicActionButton} from './topic-action-buttons';

const myStyle = makeStyles(theme => ({
    image:{
        width: 50,
    },
    flexBox:{
        display:'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    booleanInput: {
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    button:{
        color: theme.palette.primary.main,
    }
}));

const TopicPagination = (props:any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} {...props} />

const TopicInsideList = (props: any) => {
    const classes = myStyle();

    if(props.treeView){
        return <TreeDatagrid defaultOpenState={false}
        addShow={(data) => false}
        addEdit={(data) => true}
        displayName={(data) => `${data.name}`}
        role={Role.TopicEdit}
        spanContent={(data) => ``}
        {...props}
    />
    }
    return <Datagrid {...props}>
    <TextField source="id" label="ID"/>
    <FunctionField label="Kép" render={(data:any)=>
                <picture>
                    <img 
                        className={classes.image}
                        src={data.picture}
                        alt="Nincs kép"/>
                </picture>
            }/>
    <HungarianDateTimeField source="createdAt" label="Létrehozva"/>
    <TextField source="name" label="Név"/>
    <ReferenceField source="parent.id" label="Szülő" reference="tag-admin">
        <TextField source="name" />
    </ReferenceField>
    <TextField source="usageBySources" label="Forrás használja"/>
    <TextField source="usageByNews" label="Hír használja"/>
    <TextField source="usageByUsers" label="Felhasználó használja"/> 
    <FunctionField label="Parancsok" render={(data:any)=>{
        return <div className={classes.flexBox}>
            <div className={classes.booleanInput}>
                <BooleanImediateSaver value={data.isActive} endpointToCall={'tag-admin/updateBoolean'} recordId={data.id} source={'isActive'} label={'Aktív'}/>
            </div>
            <div className={classes.booleanInput}>
                <BooleanImediateSaver value={data.isBlocked} endpointToCall={'tag-admin/updateBoolean'} recordId={data.id} source={'isBlocked'} label={'Blokkolt'}/>
            </div>
            <IconButton className={classes.button} href={`#/news-admin/?displayedFilters={"tag.id"%3Atrue}&filter={"tags"%3A{"id"%3A${data.id}}}`}>
                <LibraryBooksOutlinedIcon/> 
            </IconButton>
            <EditButton basePath='tag-admin' record={data} label=''/>
        </div>
    }}/>
</Datagrid>
}

export const TopicList = (props: any) => {
    const [treeView, setTreeView] = useState(false);

    return <List {...props}
        title="Témakörök"
        filters={<TopicFilter/>}
        actions={<TopicActionButton treeView={treeView} setTreeView={setTreeView}/>}
        exporter={false}
        pagination={<TopicPagination/>}
        >
            <TopicInsideList treeView={treeView}/>
    </List>
}
