import * as React from 'react';
import { TopToolbar, EditButton } from 'react-admin';
import {getJwtData} from "../connection/auth-provider";

export const CustomShowActions = ({ basePath, data, resource, role = null }: any) => {

    const jwtData = getJwtData();

    return <TopToolbar>
        {(!role || (jwtData && jwtData.roles.includes(role))) &&
        <EditButton basePath={basePath} record={data}/>
        }
    </TopToolbar>;
};
