import React from 'react';
import { BulkEditType } from '../../fields/bulk-edit-fields/bulk-edit';
import { BulkEditButton } from '../../fields/bulk-edit-fields/edit-button';

export const NewsBulkActions = ( props:any) => {
    return <>
        <BulkEditButton selectedIds={props.selectedIds}
            resource='news-admin'
            source='isActive'
            buttonLabel="Hír aktiválás/kikapcsolás"
            dialogLabel="Kiválasztott hírek szerkesztése"
            editLabel={'Hír megjelenik'}
            bulkEndpoint='saveMore'
            type={BulkEditType.boolean}/>
        <BulkEditButton selectedIds={props.selectedIds}
            resource='news-admin'
            source='shouldDisplayPicture'
            buttonLabel="Hír kép ki-be kapcsolás"
            dialogLabel="Kiválasztott hírek szerkesztése"
            editLabel={'Hír kép megjelenik'}
            bulkEndpoint='saveMore'
            type={BulkEditType.boolean}/>
        <BulkEditButton selectedIds={props.selectedIds}
            resource='news-admin'
            source='newTagMany'
            buttonLabel="Tag hozzáadása"
            dialogLabel="Kiválasztott hírek szerkesztése"
            editLabel={'Új Tag'}
            bulkEndpoint='addTagToMany'
            type={BulkEditType.autocomplete}
            reference='tag-admin'/>
        <BulkEditButton selectedIds={props.selectedIds}
            resource='news-admin'
            source='deleteTagId'
            buttonLabel="Tag törlése"
            dialogLabel="Kiválasztott hírek szerkesztése"
            editLabel={'Törlendő tag'}
            bulkEndpoint='removeTagFromMany'
            type={BulkEditType.autocomplete}
            reference='tag-admin'/>
        </>
}