import React from 'react';
import { ChipField } from 'react-admin';
import { SingleFieldList } from 'react-admin';
import { FunctionField } from 'react-admin';
import { ReferenceField } from 'react-admin';
import {Show, SimpleShowLayout, TextField} from 'react-admin';
import { HungarianDateTimeField } from '../../fields/hungarial-date-field';
import { ReferenceObjectArrayField } from '../../fields/reference-object-array-field';

export const NewsShow = (props: any) => {

    return <Show {...props} title="Témakör" actions={/*<CustomShowActions role={Role.TopicEdit}/>*/null}>
        <SimpleShowLayout>
            <TextField source="id" label="ID"/>
            <HungarianDateTimeField source="createdAt" label="Létrehozva" addLabel/>
            <HungarianDateTimeField source="updatedAt" label="Módosítva" addLabel/>
            <TextField source="title" label="Cím"/>
            <TextField source="uri" label="url"/>
            <TextField source="sourceUrl" label="forrás url"/>
            <FunctionField source="kép" render={(data:any)=>
                        <picture>
                            <img 
                                width={200}
                                src={data.picture}
                                alt="Nincs kép"/>
                        </picture>
                    }/>
            <TextField source="picture" label="kép url"/>
            <ReferenceField source="source.id" label="forrás" reference='source-admin'>
                <TextField source="name"/>
            </ReferenceField>

            <ReferenceObjectArrayField arraySource="id" resource="news-admin" source="tags" reference="tag-admin"
                                           label="tag-ek" fullWidth addLabel>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceObjectArrayField>
        </SimpleShowLayout>
    </Show>;
};
