import React from 'react';
import { BulkEditType } from '../../fields/bulk-edit-fields/bulk-edit';
import { BulkEditButton } from '../../fields/bulk-edit-fields/edit-button';

export const BulkActions = ( props:any) => {
    return <>
        <BulkEditButton selectedIds={props.selectedIds}
            resource='source-admin'
            source='isActive'
            buttonLabel="Forrás aktiválás/kikapcsolás"
            dialogLabel="Kiválasztott források szerkesztése"
            editLabel={'Forrás aktív'}
            bulkEndpoint='saveMore'
            type={BulkEditType.boolean}/>
    </>
}