import React from 'react';
import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { BooleanInput } from 'react-admin';
import { TextInput } from 'react-admin';
import { Filter } from 'react-admin';

const renderName = (data: any) => {
    if (data) {
        return data.name;
    }
    return '';
};

export const NewsFilter = (props:any) => (
    <Filter {...props}>
        <TextInput label="Cím" source="title"/>
        <TextInput label="Lead" source="lead"/>
        <BooleanInput label="Aktív hír" source="isActive" defaultValue={true}/>
        <BooleanInput label="Aktív kép" source="shouldDisplayPicture" defaultValue={true}/>
        <ReferenceInput 
                        source='tags.id'
                        reference="tag-admin"
                        label="Tag"
                        filterToQuery={(searchText: any) => ({ 'name': searchText || '%' })}
                        fullWidth
                        allowEmpty={true}
                        emptyValue={null}
                        emptyText={''}>
                        <AutocompleteInput optionValue='id' optionText={renderName}
                                label=""
                                allowEmpty={true}
                                emptyValue={null}
                                emptyText={''}
                               />
        </ReferenceInput>
        <ReferenceInput 
                        source='source.id'
                        reference="source-admin"
                        label="Forrás"
                        filterToQuery={(searchText: any) => ({ 'name': searchText || '%' })}
                        fullWidth
                        allowEmpty={true}
                        emptyValue={null}
                        emptyText={''}>
                        <AutocompleteInput optionValue='id' optionText={renderName}
                                label=""
                                allowEmpty={true}
                                emptyValue={null}
                                emptyText={''}
                               />
        </ReferenceInput>
    </Filter>
);