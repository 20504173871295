import React from "react";
import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import {BlocklistTargetTypeEnum, getBlockListTargetTypeChoices} from "./blocklist-target-type.enum";

export const TopNewsBlocklistEdit = (props: any) => {
    return <Edit {...props} title="Tiltólista szerkesztése">
        <TopNewsBlocklistListForm {...props}/>
    </Edit>;
};

export const TopNewsBlocklistListForm = (props: any) => {
    return <SimpleForm {...props}>
        <BooleanInput source="active" label="Aktív" defaultValue={true}/>
        <SelectInput source="targetType" label="Tiltás típusa" choices={getBlockListTargetTypeChoices()}/>
        <FormDataConsumer>
            {({formData, ...rest}: any) => {
                let reference = '';
                let referenceSource = '';

                switch (formData.targetType) {
                    case BlocklistTargetTypeEnum.News:
                        reference = 'news-admin';
                        referenceSource = 'title';
                        break;
                    case BlocklistTargetTypeEnum.Tag:
                        reference = 'tag-admin';
                        referenceSource = 'name';
                        break;
                    case BlocklistTargetTypeEnum.Source:
                        reference = 'source-admin';
                        referenceSource = 'name';
                        break;
                    default:
                        return null;
                }

                return <>
                    <ReferenceInput
                        source={`targetId`}
                        reference={reference}
                        label="Tag/Hír/Forrás"
                        filterToQuery={(searchText: any) => ({[referenceSource]: searchText || '%'})}
                        fullWidth
                        allowEmpty={true}
                        emptyValue={null}
                        emptyText={''}>
                        <AutocompleteInput
                            optionValue='id'
                            optionText={(record: any) => (record?.[referenceSource] ?? '')}
                            label=""
                        />
                    </ReferenceInput>
                    {formData.targetType === BlocklistTargetTypeEnum.Tag &&
                        <BooleanInput source="blockInFeed" label="Feedben blokkolva" defaultValue={false}/>
                    }
                </>;
            }}
        </FormDataConsumer>
        <TextInput source="reason" label="Indoklás" multiline/>
    </SimpleForm>
}
