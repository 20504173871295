export const injectCustomMessages = (messages: any) => {
    messages.ra.navigation.page_rows_per_page = "Sorok száma oldalanként";
    messages.ra.auth.user_menu = "Felhasználó";
    messages.ra.action.unselect = "Ki";
    messages.ra.page.empty = "Nincs létrehozva ilyen elem";
    messages.ra.page.invite = "Hozz létre egyet!";
    messages.resources = {
        'news-admin': {
            name: 'Hír |||| Hírek',
            fields: {
                id: 'id',
                title: 'cím',
                createdAt: 'Letöltés dátuma',
            }
        },
        'source-admin': {
            name: 'Forrás |||| Források',
            fields: {
                id: 'id',
                name: 'Név',
            }
        }
    }

    return messages;
};
