import React, { useState } from 'react';
import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';

const RolesFieldItem = ({ roles, item }: any) => {

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return <React.Fragment key={item.label}>
        <ListItem button onClick={handleClick}>
            <ListItemText primary={item.label}/>
            <ListItemIcon>
                {item?.view && roles?.includes(item?.view) &&
                <VisibilityIcon/>
                }
            </ListItemIcon>
            <ListItemIcon>
                {item?.edit && roles?.includes(item?.edit) &&
                <EditIcon/>
                }
            </ListItemIcon>
            {(item?.children?.length > 0) && (open ? <ExpandLess/> : <ExpandMore/>)}
        </ListItem>
        {item?.children?.length > 0 &&
        <Collapse in={open}
                  timeout="auto"
                  unmountOnExit>
            <RolesFieldView rolesTree={item?.children} roles={roles} marginLeft={24}/>
        </Collapse>
        }
    </React.Fragment>;
};

const RolesFieldView = ({ roles, rolesTree, marginLeft = 0, ...props }: any) => {
    const children = rolesTree?.map((item: any) => {
        return <RolesFieldItem key={item.label} item={item} rolesTree={rolesTree} roles={roles}/>;
    });

    return <List style={{ paddingLeft: marginLeft }} component="nav">
        {children}
    </List>;
};

export const RolesField = ({ record, rolesTree, ...props }: any) => {
    return <RolesFieldView roles={record.roles.map((role: any) => role.name)} rolesTree={rolesTree}/>;
};
