import React from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import { Settings as SettingsIcon } from "@material-ui/icons";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {getJwtData} from "../connection/auth-provider";

const CustomUserMenu = (props: any) => {
    const jwtData = getJwtData();

    const username = (jwtData || {}).name || '';

    return <UserMenu label={username || "Felhasználó"} {...props}>
        <MenuItemLink
            to="/theme-settings"
            primaryText="Téma"
            leftIcon={<SettingsIcon/>}
        />
        <MenuItemLink
            to="/new-password"
            primaryText="Új jelszó"
            leftIcon={<VpnKeyIcon/>}
        />
    </UserMenu>;
};

export default CustomUserMenu;
