import { makeStyles, } from "@material-ui/core";

export const useCommonStyles = makeStyles({
    icon: {
        'margin-right': '7px',
        'margin-top': '-4px',
    },
});

export const useDialogStyles = makeStyles({
    button: {
        margin: '10px 24px',
        position: 'relative',
    },
    dialog: {
        margin: '0px',
        padding: '0px',
    },
    toolbar: {
        padding: '10px 24px',
    }
});
