import React, { useState } from 'react';
import { FileInput } from 'react-admin';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { PictureField } from './picture-field';

export const PictureInput = (props: any) => {
    let data = props.source ? get(props.record, props.source) : props.record;

    if (typeof data === 'undefined') {
        data = props.record;
    }


    /*
     this is a bugfix.
     if we delete any item, the last item will be deleted in the view
     (however on save everything works normal), so we save the first showed item, so the view works normal.
    */
    const [element] = useState(data);

    const doesPictureExists = typeof element !== 'undefined' && element && element.hasOwnProperty('id');

    if (!doesPictureExists || props.isEditable) {
        return <FileInput multiple={false} {...props} record={element} label={props?.title || "Fájl"} accept={props?.accept || 'image/*'}
                          fullWdith>
            <PictureField height={props.height} record={data} style={props.style} noHeight={props?.noHeight}/>
        </FileInput>;
    }

    return <Box display="flex" p={1} justifyContent="center">
        <PictureField record={{ data }} source="data" height={props.height} style={props.style}
                      noHeight={props?.noHeight}/>
    </Box>;
};


PictureInput.propTypes = {
    isEditable: PropTypes.bool,
    height: PropTypes.string,
    record: PropTypes.any,
    source: PropTypes.string,
    resource: PropTypes.string,
    data: PropTypes.any,
    pictures: PropTypes.any,
    fullWidth: PropTypes.bool,
    style: PropTypes.object,
    validate: PropTypes.array,
    noHeight: PropTypes.bool,
    accept: PropTypes.any,
    title: PropTypes.string
};

