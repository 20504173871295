import {PasswordInput, SimpleForm} from 'react-admin';
import React, {useState} from 'react';
import {required, useNotify} from 'ra-core';
import {DefaultToolbar} from './default-toolbar';
import {config} from "../connection/config";

export const NewPasswordForm = (props: any) => {
    const notify = useNotify();
    const [saving, setSaving] = useState(false);
    const handleSave = (values: any) => {
        setSaving(true);
        fetch(`${config.apiUrl}/new-password/changeAdminPassword`, {
            method: 'POST',
            body: JSON.stringify({
                ...values
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt-token')}`
            }
        }).then(res => {
            if (res.ok) {
                notify('Új jelszó elementve!');
                setSaving(false);
            } else {
                res.json().then(result => {
                    notify(result.message);
                    setSaving(false);
                });
            }
        }).catch(e => {
            notify('Valami hiba történt!');
            setSaving(false);
        });
    };

    return (
        <SimpleForm
            save={handleSave}
            saving={saving}
            redirect={false}
            toolbar={
                <DefaultToolbar
                    submitting={saving}
                    style={{backgroundColor: 'transparent'}}
                />
            }
            {...props}>
            <PasswordInput label="Jelenlegi jelszó" source="current_password" validate={[required()]} fullWidth
                           initialValue=''/>
            <PasswordInput label="Új jelszó" source="first_new_password" validate={[required()]} fullWidth
                           initialValue=''/>
            <PasswordInput label="Új jelszó újra" source="second_new_password" validate={[required()]} fullWidth
                           initialValue=''/>
        </SimpleForm>
    );
};
