import React from 'react';
import {
    List,
} from 'react-admin';
import { ListActionsWithSortButton } from '../../../fields/actions-sort-button';
import { TagSuggestionBulkActions } from './bulkactions';
import { TagSuggestionFilter } from './filters';
import { SuggestionDataGrid } from './suggestiongrid';

export const TagSuggestedMergeList = (props: any) => {

    return <List {...props} 
                actions={<ListActionsWithSortButton sortFields={['title', 'id', 'createdAt']}/>}
                bulkActionButtons={
                    <TagSuggestionBulkActions/>}
                title="Hírek"
                 filters={<TagSuggestionFilter/>}
                 exporter={false}
        >
            <SuggestionDataGrid/>
    </List>
}
