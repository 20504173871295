import {
    Filter,
    TextInput,
    SelectInput
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import {getRoleName, Role} from "../../utils/role";

const roleToSelectItem = (role: Role) => {
    return { id: role, name: getRoleName(role) };
};

export const AdminUserFilter = ({ rolesTree, ...props }: any) => {
    const [roles, setRoles] = useState<any[]>([]);

    useEffect(() => {
        const _roles: any[] = [];
        rolesTree?.forEach((item: any) => {
            if (item?.view) _roles.push(roleToSelectItem(item.view));
            if (item?.edit) _roles.push(roleToSelectItem(item.edit));
            item?.children?.forEach((_item: any) => {
                if (_item?.view) _roles.push(roleToSelectItem(_item.view));
                if (_item?.edit) _roles.push(roleToSelectItem(_item.edit));
            });
        });
        setRoles(_roles);
    }, [rolesTree]);

    return <Filter {...props}>
        <TextInput source="nameEmailFilter" label="Név/Email"/>
        <TextInput source="id" label="Id"/>
        <SelectInput source="roles.name" label="Jogosultság" choices={roles}/>
    </Filter>;
};
