import { useDialogStyles } from './common-styles';
import React, { useState } from 'react';
import {
    Toolbar,
    SaveButton,
    DeleteButton
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

export const DefaultToolbar = ({ submitting, onCancel, ...props }: any) => {
    const classes = useDialogStyles();
    return <Toolbar {...props} disableGutters className={classes.toolbar}>
        <SaveButton/>
    </Toolbar>;
};

export const ConfirmToolbar = ({ submitting, onCancel, confirmLabel = 'Biztosan mented a változásokat?', ...props }: any) => {
    const classes = useDialogStyles();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    return <Toolbar {...props} disableGutters className={classes.toolbar} style={{
        display: 'flex',
        justifyContent: 'space-between'
    }}>
        <SaveButton
            onClick={() => setConfirmDialogOpen(true)}
            saving={false}
            handleSubmitWithRedirect={() => {
            }}
        />
        <Button
            onClick={() => {
                setConfirmDelete(true);
            }}
            style={{ color: '#ff5131' }}
        >
            <DeleteIcon/><span style={{ paddingLeft: '0.5em' }}>Törlés</span>
        </Button>

        <Dialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
        >
            <DialogTitle>
                {confirmLabel}
            </DialogTitle>
            <DialogActions>
                <SaveButton
                    onClick={() => {
                        setConfirmDialogOpen(false);
                    }}
                    saving={props?.saving}
                    handleSubmitWithRedirect={props?.handleSubmitWithRedirect}
                    // disabled={formProps.disableSaveButton}
                />
                <Button color="primary" onClick={() => setConfirmDialogOpen(false)}>
                    Mégse
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
        >
            <DialogTitle>
                Biztosan törölni szeretnéd?
            </DialogTitle>
            <DialogActions>
                <DeleteButton
                    onClick={() => {
                        setConfirmDelete(false);
                    }}
                    {...props}
                />
                <Button color="primary" onClick={() => setConfirmDelete(false)}>
                    Mégse
                </Button>
            </DialogActions>
        </Dialog>
    </Toolbar>;
};
