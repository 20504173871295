
export const inflateToTree = (flatData:any, parentId = null) => {

    // if (flatData.length > 1000) {
    //     flatData = Object.assign([], flatData);
    // } else {
    //     flatData = cloneDeep(flatData)
    // }

    const level = flatData.filter((data:any) => data && ((data?.parent || {}).id || null) === parentId);

    return level
        .map((item:any) => {
            item.children = inflateToTree(flatData, item.id).map((child:any) => {
                child.parent = item;
                return child;
            });

            item.parent = null;

            return item;
        })
        .sort((a:any, b:any) => (a?.order || 9999) - (b?.order || 9999));
};
