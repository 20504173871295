import {Role} from "./role";

export const rolesTree = [
    { label: 'Témakörök', view: Role.TopicView, edit: Role.TopicEdit },
    { label: 'Admin Felhasználók', view: Role.AdminUserView, edit: Role.AdminUserEdit },
    { label: 'Hírek', view: Role.NewsView, edit: Role.NewsEdit },
    { label: 'Hír Források', view: Role.SourceView, edit: Role.SourceEdit },
    { label: 'Blokkolások', view: Role.TopNewsBlocklistView, edit: Role.TopNewsBlocklistEdit },
    { label: 'Facebook', edit: Role.FacebookSetting },
];
