import {required, useNotify} from "ra-core";
import React from "react";
import {ArrayInput, BooleanInput, Create, FunctionField, SimpleForm, SimpleFormIterator, TextInput} from "react-admin";
import {PictureInput} from "../../fields/picture/picture-input";
import {getWithAuth, postWithAuth} from "../../connection/fetch-with-auth";
import {Button, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";

export const SourceCreate = ({ ...props }: any) => {
    return <Create {...props} title='Forrás létrehozása' >
        <SourceForm {...props}/>
    </Create>
}

export const SourceForm = (props: any) => {

    const [mostUsedPictures, setMostUsedPictures] = React.useState([]);
    const [mostUsedOpen, setMostUsedOpen] = React.useState(false);

    React.useEffect(() => {
        if (props?.record?.id) {
            getWithAuth(`source-admin/${props.record.id}/mostUsedPictures`)
                .then((res: any) => {
                    setMostUsedPictures(res?.data || []);
                })
        }
    }, [props.record?.id])

    return <SimpleForm {...props}>
        <TextInput source='name' label="Név" validate={required()} fullWidth/>
        <TextInput source='feedUrl' label="Feed Url" validate={required()} fullWidth/>
        <PictureInput style={{ width: 500 }}
                      isEditable={true}
                      accept={['image/png', 'image/webp']}
                      noHeight
                      record={props.record}
                      source="pictureInserted"
                      fullWidth/>
        <TextInput source='note' label="Megjegyzés" fullWidth/>
        <BooleanInput source='isActive' label="Aktív" initialValue={true}/>

        {props?.record?.id &&
        <ArrayInput source="defaultPictures" label="Default képek">
            <SimpleFormIterator>
                <TextInput source="picture" label="Kép url" fullWidth/>
                <FunctionField render={(data: any) => {
                    return <img src={data.picture} width={150} alt="Kép"/>
                }} addLabel={false}/>
            </SimpleFormIterator>
        </ArrayInput>
        }

        <Button color="primary" variant="contained" onClick={() => setMostUsedOpen(true)} style={{width: 400}}>
            Legtöbbet előforduló képek felvétele
        </Button>

        <div>
            <Dialog
                open={mostUsedOpen}
                onClose={() => setMostUsedOpen(false)}
            >
                <DialogTitle>Legtöbbet előforduló képek</DialogTitle>
                <DialogContent>
                    <MostUsedPicturesDialog mostUsedPictures={mostUsedPictures} sourceId={props.record.id}/>
                </DialogContent>
            </Dialog>
        </div>

    </SimpleForm>
}

const MostUsedPicturesDialog = (props: any) => {
    const notify = useNotify();
    const classes = useStyles();

    const handleAdd = (picture: string) => {
        postWithAuth(`source-admin/${props.sourceId}/addDefaultPicture`, {
            picture: picture
        })
            .then((res: any) => {
                notify(res?.data);
            })
    }

    return <div>
        <div style={{marginBottom: 10 }}>Hozzáadás után úrja kell frissíteni kell az adatokat jobb felül, hogy megjelenjenek az új képek</div>

        {props?.mostUsedPictures?.map((picture: any, index: number) => (

            <div key={index} className={classes.itemRoot}>
                <img
                    width={150}
                    src={picture.picture}
                    alt="Kép"/>
                <div className={classes.countLabel}>Előrodulás: {picture.count}</div>
                <div className={classes.grow}/>
                <Button startIcon={<AddIcon/>} color="primary" onClick={() => handleAdd(picture.picture)}>
                    Hozzáadás
                </Button>

            </div>
        ))}
    </div>
}

const useStyles = makeStyles(() => ({
    itemRoot: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 430,
        padding: 6
    },
    countLabel: {
        padding: 6
    },
    grow: {
        flex: 1
    }
}))
