import * as React from 'react';
import {BooleanInput} from 'react-admin';
import {postWithAuth} from '../connection/fetch-with-auth';
import {Form} from 'react-final-form';

interface Props {
    value: boolean;
    recordId: number;
    source: string;
    endpointToCall: string;
    label: string;
}

export const BooleanImediateSaver = (props: Props) => {
    //const refresh = useRefresh();
    const save = async (val:any)=>{
        const res = await postWithAuth(props.endpointToCall, {'id': props.recordId, 'column':props.source, 'value': val});
        if(res.status===201 || res.status===200) {
            console.log('ok');
        }
    }
    return (
        <Form
            onSubmit={(data:any)=>{console.log(data)}}
            render={(formProps:any) => (
                <form>
                    <BooleanInput initialValue={props.value} onChange={save} source={props.source} label={props.label}/>
                </form>
            )}
        />
    );
}
export default BooleanImediateSaver;
