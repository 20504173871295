import {FormControl, InputLabel, MenuItem, Paper, Select, useMediaQuery} from '@material-ui/core';
import React from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {createMuiTheme} from '@material-ui/core/styles';
import useLocalStorage from 'react-use-localstorage';
import {FlexBox} from "./flex-box";

export const LOCAL_STORAGE_DARK_MODE = 'dark_mode';
export const LOCAL_STORAGE_THEME_COLOR = 'theme_color';

export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';
export const THEME_AUTO = 'auto';

export const THEME_COLOR_GREEN = 'green';
export const THEME_COLOR_RED = 'red';
export const THEME_COLOR_BLUE = 'blue';
export const THEME_COLOR_PINK = 'pink';
export const THEME_COLOR_ORANGE = 'orange';

const themes: any = {
    [THEME_COLOR_BLUE]: {
        dark: {
            type: 'dark',
            primary: '#FF5252',
            secondary: '#009ee3',
            error: '#ff5131'
        },
        light: {
            type: 'light',
            primary: '#FF5252',
            secondary: '#2196f3',
            error: '#f44336'
        }
    },
    [THEME_COLOR_GREEN]: {
        dark: {
            type: 'dark',
            primary: '#9ccc65',
            secondary: '#00796b',
            error: '#ff5131'
        },
        light: {
            type: 'light',
            primary: '#607D8B',
            secondary: '#009688',
            error: '#f44336'
        }
    },
    [THEME_COLOR_RED]: {
        dark: {
            type: 'dark',
            primary: '#66bb6a',
            secondary: '#d32f2f',
            error: '#ff3d00'
        },
        light: {
            type: 'light',
            primary: '#388e3c',
            secondary: '#d32f2f',
            error: '#ff3d00'
        }
    },
    [THEME_COLOR_PINK]: {
        dark: {
            type: 'dark',
            primary: '#ba68c8',
            secondary: '#f48fb1',
            error: '#ff3d00'
        },
        light: {
            type: 'light',
            primary: '#7b1fa2',
            secondary: '#e91e63',
            error: '#ff3d00'
        }
    },
    [THEME_COLOR_ORANGE]: {
        dark: {
            type: 'dark',
            primary: '#ff5722',
            secondary: '#ff8a50',
            error: '#ff3d00'
        },
        light: {
            type: 'light',
            primary: '#ff5722',
            secondary: '#ff8a50',
            error: '#ff3d00'
        }
    }
};

export const getCurrentTheme = () => {
    const themeColor = localStorage.getItem(LOCAL_STORAGE_THEME_COLOR) || THEME_COLOR_BLUE;

    return themes[themeColor];
};

export const getStyle = (theme: any) => {
    return createMuiTheme({
        palette: {
            type: theme.type,
            primary: {
                main: theme.primary
            },
            secondary: {
                main: theme.secondary
            },
            error: {
                main: theme.error
            }
        }
    });
};

export const useThemeColor = () => useLocalStorage(LOCAL_STORAGE_THEME_COLOR, THEME_COLOR_BLUE);

export const useDarkMode = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const [darkMode] = useDakModeSetting();

    if (darkMode === THEME_AUTO) {
        return prefersDarkMode;
    }

    return darkMode === THEME_DARK;
};

export const useDakModeSetting = () => {
    return useLocalStorage(LOCAL_STORAGE_DARK_MODE, THEME_LIGHT);
};

export const ThemeSettings = (props: any) => {

    const [themeColor] = useThemeColor();
    const [darkMode] = useDakModeSetting();

    const { setThemeColor, setDarkMode } = props;

    const handleAlignment = (event: any, value: any | null) => {
        if (value !== null) {
            if (value === THEME_AUTO) {
                setDarkMode(THEME_LIGHT);
                setThemeColor(THEME_COLOR_BLUE);
            } else setDarkMode(value);
        }
    };

    return <Paper style={{ height: '100%', padding: '20px' }}>
        <FlexBox>
            <ToggleButtonGroup exclusive onChange={handleAlignment} value={darkMode}>
                <ToggleButton key={1} value={THEME_AUTO}>Automatikus</ToggleButton>
                <ToggleButton key={2} value={THEME_LIGHT}>Világos mód</ToggleButton>
                <ToggleButton key={3} value={THEME_DARK}>Sötét mód</ToggleButton>
            </ToggleButtonGroup>
        </FlexBox>
        <br/>
        <FlexBox>
            <FormControl variant="filled">
                <InputLabel id="color-selctor">Szín</InputLabel>
                <Select
                    labelId="color-selctor"
                    value={themeColor}
                    onChange={(event: any) => {
                        setThemeColor(event.target.value);
                    }}>
                    <MenuItem value={THEME_COLOR_GREEN}>Zöld</MenuItem>
                    <MenuItem value={THEME_COLOR_BLUE}>Kék</MenuItem>
                    <MenuItem value={THEME_COLOR_RED}>Piros</MenuItem>
                    <MenuItem value={THEME_COLOR_PINK}>Rózsaszín</MenuItem>
                    <MenuItem value={THEME_COLOR_ORANGE}>Narancssárga</MenuItem>
                </Select>
            </FormControl>
        </FlexBox>
    </Paper>;
};

