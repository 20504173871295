import React from 'react';
import { BooleanInput } from 'react-admin';
import { NumberInput } from 'react-admin';
import { TextInput } from 'react-admin';
import { Filter } from 'react-admin';
import { HungarianDateTimeInput } from '../../fields/hungarian-date-input';

export const TopicFilter = (props:any) => (
    <Filter {...props}>
        <TextInput label="Név" source="name"/>
        <BooleanInput label="Aktív" source="isActive" defaultValue={true}/>
        <BooleanInput label="Blokkolt" source="isBlocked" defaultValue={true}/>
        <HungarianDateTimeInput label="Létrehozva dátum-tól" source="createdAtMin" defaultValue={true}/>
        <HungarianDateTimeInput label="Létrehozva dátum-ig" source="createdAtMax" defaultValue={true}/>
        <NumberInput label="minimum hír használja" source="minNewsUsage" defaultValue={true}/>
        <NumberInput label="maximum hír használja" source="maxNewsUsage" defaultValue={true}/>
    </Filter>
);