import { Box } from "@material-ui/core";
import React, { Children } from "react";

export const FlexBox = ({ children, ...otherProps }: any) => {
    return <Box display="flex">
        {Children.map(children, child => {
            return <Box flex={child.props.flex} mr={child.props.mr} ml={child.props.ml}>
                {React.cloneElement(child, otherProps)}
            </Box>;
        })}
    </Box>;
};
