import React from "react";
import {BooleanField, Datagrid, Filter, FunctionField, List, ReferenceField, SelectInput, TextField} from "react-admin";
import {EnumField} from "../../fields/enum.field";
import {
    BlocklistTargetTypeEnum,
    getBlockListTargetTypeChoices,
    getBlocklistTargetTypeName
} from "./blocklist-target-type.enum";

export const TopNewsBlocklistList = (props: any) => {
    return <List
        {...props}
        title="Tiltólista"
        exporter={false}
        filters={<TopNewsBlocklistFilter/>}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID"/>
            <EnumField source="targetType" label="Típus" translatorFunction={getBlocklistTargetTypeName}/>
            <TopNewsBlocklistReferenceField/>
            <BooleanField source="active" label="Aktív"/>
            <FunctionField label="Feedben blokkolva" render={(record: any) => {
                if (record.targetType !== BlocklistTargetTypeEnum.Tag) {
                    return null;
                }
                return <BooleanField source="blockInFeed" record={record}/>
            }}/>
        </Datagrid>
    </List>
};

const TopNewsBlocklistFilter = (props: any) => {
    return <Filter {...props}>
        <SelectInput source="targetType" label="Tiltás típusa" choices={getBlockListTargetTypeChoices()}/>
    </Filter>
}

const TopNewsBlocklistReferenceField = (props: any) => {
    return <FunctionField {...props} label="Cím" render={(record: any) => {
        let reference = '';
        let referenceSource = '';

        switch (record.targetType) {
            case BlocklistTargetTypeEnum.News:
                reference = 'news-admin';
                referenceSource = 'title';
                break;
            case BlocklistTargetTypeEnum.Tag:
                reference = 'tag-admin';
                referenceSource = 'name';
                break;
            case BlocklistTargetTypeEnum.Source:
                reference = 'source-admin';
                referenceSource = 'name';
                break;
        }

        return <ReferenceField {...props} record={record} source="targetId" label="Név" reference={reference}>
            <TextField source={referenceSource}/>
        </ReferenceField>
    }}/>;
}
