import {Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';
import React from 'react';
import {HungarianDateTimeField} from '../../fields/hungarial-date-field';

const TagMergeFilter = (props:any)=> (
    <Filter {...props}>
        <TextInput label="Eredeti név" source="tagValue"/>
        <TextInput label="Új tag név" source="toTag.name"/>
    </Filter>
);

export const TagMergeList = ({ ...props }: any) => {

    return <List {...props} title="Tag Összevonások" filters={<TagMergeFilter/>} exporter={false}
                 bulkActionButtons={false}
    >
        <Datagrid rowClick="show">
            <TextField source="id" label="ID"/>
            <HungarianDateTimeField source="createdAt" label="Létrehozva"/>
            <TextField source="createdBy.name" label="Létrehozta"/>
            <TextField source="tagValue" label="Eredeti név"/>
            <ReferenceField source="toTag.id" label="Tagbe kerül" reference="tag-admin">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>;
};
