import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect
} from 'react-admin';
import React, {useState} from 'react';
import {Button, Typography} from "@material-ui/core";
import {PictureInput} from '../../fields/picture/picture-input';
import {Form} from 'react-final-form';
import {postWithAuth} from '../../connection/fetch-with-auth';
import {UNDOABLE} from 'ra-core';

export const TopicEdit = (props: any) => {
    return <Edit {...props} title="Témakör szerkesztése">
        <TopicForm/>
    </Edit>;
};
const MergeToTopicButton =(props:any) => {
    const [mergeToId, setMergeToId] = useState(-1);
    const notify = useNotify();
    const redirect = useRedirect();
    const merge = async ()=>{
        await postWithAuth('tag-admin/mergeTag', {'mergeThisId': props.record.id, 'mergeToId': mergeToId})
        .then(
            (data)=>{
                if(data.data === 'OK'){
                    notify("Tag beolvasztása sikeresen megtörtént", 'success', UNDOABLE);
                    redirect('/tag-admin');
                }
                else {
                    notify(data.data, 'error', UNDOABLE);
                }
            }
        ).catch(
            (error:any)=>{
                console.log('error', error);
            }
        );
    }
    const onChangeValue = (value:any)=>{
        setMergeToId(value);
    }
    return <Form
    onSubmit={(data:any)=>{console.log(data)}}
    render={(formProps:any) => (
        <form>
            <ReferenceInput 
                source='mergeToTag' 
                onChange={onChangeValue}
                reference='tag-admin'
                label='Beolvasztás ebbe a Tag-be'
                filterToQuery={(searchText: any) => ({ 'name': searchText || '%' })}
                fullWidth>
                <AutocompleteInput
                    optionValue="id" 
                    optionText={(data:any)=>{
                        if(data){
                            return data.name;
                        }
                        return 'nincs neve';
                    }}
                    label=""
                    shouldRenderSuggestions={(val: any) => {
                        return val.trim().length > 2;
                    }}
                    allowEmpty={true}
                    emptyValue={null}
                    emptyText={''}/>
            </ReferenceInput>
            <Button onClick={merge} disabled={mergeToId===-1}>
                Beolvasztás
            </Button>
        </form>
    )}
/>
}

export const TopicForm = (props: any) => {
    return <SimpleForm {...props}>
        <TextInput source='name' label="Név" validate={required()} fullWidth/>
        <PictureInput style={{ width: 150 }}
            isEditable={true}
            accept={['image/png', 'image/webp']}
            noHeight
            record={props.record}
            source="pictureInserted"
            fullWidth/>
        <ReferenceInput 
            source='parent.id' 
            reference='tag-admin'
            label='Szülő'
            filterToQuery={(searchText: any) => ({ 'name': searchText || '%' })}
            fullWidth>
            <AutocompleteInput
                optionValue="id" 
                optionText={(data:any)=>{
                    if(data){
                        return data.name
                    }
                    return 'nincs neve'
                }}
                label=""
                shouldRenderSuggestions={(val: any) => {
                    return val.trim().length > 2;
                }}
                allowEmpty={true}
                emptyValue={null}
                emptyText={''}/>
        </ReferenceInput>
        <BooleanInput source='isActive' label="Aktív" initialValue={true}/>
        <BooleanInput source='isBlocked' label="Blokkolt" initialValue={false}/>
        <Typography style={{width:'100%'}}>
            Tag beolvasztása egy másik létező Tag-be
        </Typography>
        <MergeToTopicButton source="id"/>
    </SimpleForm>
}
